@import '../helpers/mixins';
@import '../lib/include-media';

/* Tables */

th {
  a {
    color: $white;
    font-weight: 700;
    text-decoration: underline;
  }

  a::after {
    content: " \00BB";
  }

  a:hover,
  a:active,
  a:focus {
    color: $clr15;
  }
}

thead th,
thead td {
  background: $clr31;
  color: $white;
}

.table-borderless td,
.table-borderless th {
  border: none !important;
}

/* Override Bootstrap default of bottom */
.table > thead > tr > th {
  vertical-align: middle;
}

.table-hover > tbody > tr:nth-of-type(odd) {
  @include media('<=tablet-md') {
    background-color: $clr27;
  }
}

.table-fixed {
  table-layout: auto;

  @include media('>=tablet') {
    table-layout: fixed;
  }
}
