@import '../variables/bootstrap-custom';

/* tabbed content */

/* From: esc-horz-localnav */

/* main-nav-subsite stacked */
.main-nav-subsite .nav-tabs {
  border: none;
  font-size: 16px;
}

.main-nav-subsite ul.nav.nav-tabs {
  padding: 0 0 3px;
}

.main-nav-subsite .nav-tabs > li {
  float: none;
  line-height: 1.6;
}

.main-nav-subsite {
  position: relative;
  z-index: 0;
}

.container.main-nav-subsite {
  background: #ee8d00;
  padding-top: 2px;
}

.main-nav-subsite .nav-tabs > li a {
  /* font-size: 1.1em; */
  font-weight: 700;
  color: #fff;
  position: relative;
  display: block;
  padding: 4px 10px;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 0;
  margin-right: 0;
}

.main-nav-subsite .nav-tabs > li .currentbranch0 > a {
  background-color: #e0832a;
}

.main-nav-subsite .nav > li a:hover,
.main-nav-subsite .nav > li a:focus,
.main-nav-subsite .nav > li a:active,
.main-nav-subsite .nav > li > .currentbranch0 a:hover,
.main-nav-subsite .nav > li > .currentbranch0 a:focus,
.main-nav-subsite .nav > li > .currentbranch0 a:active {
  background-color: #e0832a;
}

.main-nav-subsite .nav-tabs > li a:hover,
.main-nav-subsite .nav-tabs > li a:focus,
.main-nav-subsite .nav-tabs > li a:active,
.main-nav-subsite .nav-tabs > li > .currentbranch0 a:hover,
.main-nav-subsite .nav-tabs > li > .currentbranch0 a:focus,
.main-nav-subsite .nav-tabs > li > .currentbranch0 a:active {
  background: #e0832a none;

  /* *zoom: 1; // IE 5.5-7 Only */
  border: 0;
}

.main-nav-subsite .multilevel-linkul-0 > li {
  display: none;
}

.main-nav-subsite .nav-pills {
  margin-top: 3px;
  margin-right: 10px;
  float: right;
}

.main-nav-subsite .nav-pills > li > a {
  border: 2px solid #fff;
  padding: 4px 10px;
}

.main-nav-subsite .nav-pills > li > a:hover {
  border-color: #999;
}

/******  nav-tabs tabbed  *******/
@media (min-width: 941px) {
  .main-nav-subsite ul.nav.nav-tabs {
    padding: 0;
  }

  .main-nav-subsite .nav-tabs > li {
    float: left;
    margin: 0 0 -1px 1px;
  }

  .main-nav-subsite .nav-tabs > li a {
    margin-right: 2px;
    border-radius: 4px 4px 0 0;
    border: 1px solid white;
    background: #e8e7e6;
    color: #8d8175;
  }

  .main-nav-subsite .nav-tabs > li .currentbranch0 > a {
    background-color: #fff;
  }

  .main-nav-subsite .nav-tabs > li a:hover,
  .main-nav-subsite .nav-tabs > li a:focus,
  .main-nav-subsite .nav-tabs > li a:active {
    background: #fff;
    background-image: -moz-linear-gradient(top, #e8e7e6, #fff);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#e8e7e6), to(#fff));
    background-image: -webkit-linear-gradient(top, #e8e7e6, #fff);
    background-image: -o-linear-gradient(top, #e8e7e6, #fff);
    background-image: linear-gradient(to bottom, #e8e7e6, #fff);
    background-repeat: repeat-x;

    /* filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffe8e7e6', endColorstr='#ffffffff', GradientType=0); // No longer supported
     *zoom: 1; // IE 5.5-7 Only */
    border: 1px solid white;
    color: #645b53;
  }

  .main-nav-subsite .nav-tabs > li.currentbranch0 > a,
  .main-nav-subsite .nav-tabs > li > .currentbranch0 a:hover,
  .main-nav-subsite .nav-tabs > li > .currentbranch0 a:focus,
  .main-nav-subsite .nav-tabs .open > a,
  .main-nav-subsite .nav-tabs .open > a:hover,
  .main-nav-subsite .nav-tabs .open > a:focus {
    background-color: #fff;
    background-image: -moz-linear-gradient(top, #fff, #fff);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#fff));
    background-image: -webkit-linear-gradient(top, #fff, #fff);
    background-image: -o-linear-gradient(top, #fff, #fff);
    background-image: linear-gradient(to bottom, #fff, #fff);

    /* filter: progid:dximagetransform.microsoft.gradient(startColorstr='#fff', endColorstr='#fff', GradientType=0); // No longer supported */
    border: 1px solid white;
  }
}

/* From: esc-contextual-working */

.currentbranch0 {
  display: inline-block;
  margin-bottom: -2px;
}

.nav-tabs {
  font-size: 95%;

  & > li > a,
  & > li > a:focus,
  & > li > a:hover {
    outline: none !important;
    border: 1px solid #dedede;
  }

  & > li.active > a:focus {
    text-decoration: underline;
  }
}

.tab-pane {
  padding: 20px;
  border: 1px solid $clr9;
  border-top: 0;
}
