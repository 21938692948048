@import '../variables/bootstrap-custom';
@import '../helpers/mixins';
@import '../lib/include-media';

/* fixing mobile homepage font sizes */
.media-box,
.offer-block .article-box {
  font-size: 14px;
}

/* Video iFrame */
.banner.video-section::after {
  background: rgba(0, 0, 0, 0);
}

/* 2017 new header top */

.oh-cta-container {
  background: $white;
}

@media (min-width: 768px) {
  .oh-cta-container {
    background: url(../images/student-with-child.jpg) right/contain no-repeat;
    height: 250px;
  }
}

@media (min-width: 992px) {
  .oh-cta-container {
    height: 400px;
  }
}

@media (min-width: 1200px) {
  .oh-cta-container {
    max-width: 1600px;
    background: url(../images/student-with-child-fade.jpg) right/contain no-repeat;
    float: none;
    margin: 0 auto;
    padding-top: 30px;
  }
}

.oh-cta {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  font-size: 24px;
}

@media (min-width: 768px) {
  .oh-cta {
    margin-left: 10px;
    font-size: 28px;
    margin-top: 0;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .oh-cta {
    font-size: 36px;
    margin-top: 30px;
    margin-left: 80px;
  }
}

@media (min-width: 1200px) {
  .oh-cta {
    margin-top: 0;
  }
}

.oh-cta-bold {
  font-weight: bold;
  position: relative;
  color: $clr24;
}

.oh-cta span:nth-child(2) {
  padding-right: 75px;
}

.oh-cta-orange {
  color: $gray-base;
  background: $clr13;
  display: inline-block;
  padding-left: 20px;
  padding-right: 25px;
  margin-top: 10px;
  margin-left: -8px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .oh-cta-orange {
    padding-right: 75px;
  }

  .oh-cta span:nth-child(2) {
    padding-right: 200px;
  }
}

@media (min-width: 992px) and (max-width: 1120px) {
  .oh-cta-orange {
    padding-right: 10px;
  }

  .oh-cta span:nth-child(2) {
    padding-right: 100px;
  }
}

.oh-cta-half {
  text-align: center;
}

.oh-cta-half img {
  height: 75px !important;
  width: auto !important;
}

@media (min-width: 768px) {
  .oh-cta-half {
    width: 50%;
    text-align: right;
    max-width: 700px;
  }

  .oh-cta-half img {
    height: 115px !important;
  }
}

@media (min-width: 992px) {
  .oh-cta-half img {
    height: auto !important;
  }
}

.oh-cta-bottom-gray {
  background: $clr24;
  padding: 10px 0;
}

@media (min-width: 768px) {
  .oh-cta-bottom-gray {
    padding: initial;
  }
}

.oh-cta-bottom-text {
  color: $white;
  text-align: center;
}

.oh-cta-bottom-text h2 {
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  font-size: 28px;

  @include media('>tablet') {
    margin: 10px 20px;
    font-size: 36px;
  }

  a {
    color: $white;

    &:hover,
    &:focus {
      color: $clr15;
    }
  }
}

.video-half {
  vertical-align: middle;
}

.video-half .row {
  position: relative;
}

@media (min-width: 768px) {
  .video-half .row {
    padding: 30px 0;
  }
}

@media (min-width: 1200px) {
  .video-half .row {
    padding: 60px 0;
  }
}

.video-half .guide-line::before {
  left: 0;
  opacity: 1;
}

/* @media (max-width: 767px) {
  .offer-block .animated-line {
    display: none !important;
  }
} */

.video-half .col-sm-6 {
  padding: 0;
}

@media (max-width: 767px) {
  .video-half .col-sm-pull-6 {
    padding: 30px;
  }
}

.video-half .col-sm-pull-6 h2 {
  margin-top: 0;
}

.video-half .banner {
  background: $clr7;
}

@media (min-width: 768px) {
  .video-half .banner {
    height: 210px;
  }
}

@media (min-width: 992px) {
  .video-half .banner {
    height: 275px;
  }
}

@media (min-width: 1200px) {
  .video-half .banner {
    height: 330px;
  }
}

.video-half .banner .holder {
  min-height: calc(100vw * 9 / 16);
  align-items: center;

  /* padding: 50px 0 0; */
  padding: 0;
  margin-bottom: -1%;
  width: auto;
}

@media (min-width: 641px) {
  .video-half .banner .holder {
    /* padding-top: 25%; */
  }
}

@media (min-width: 768px) {
  .video-half .banner .holder {
    min-height: 225px;

    /* padding-top: 67px; */
    padding-top: 75px;
    margin-bottom: -14px;
  }
}

@media (min-width: 992px) {
  .video-half .banner .holder {
    min-height: 290px;

    /* padding-top: 100px; */
    padding-top: 290px;
    margin-bottom: -17px;
  }
}

@media (min-width: 1200px) {
  .video-half .banner .holder {
    min-height: 350px;

    /* padding-top: 130px; */
    padding-top: 330px;
    margin-bottom: -22px;
  }
}

.video-half .banner .align {
  padding-bottom: 0;
}

.video-half .intro-video-holder {
  margin-top: 0;
}

.video-half .guide-line-hide {
  opacity: 0 !important;
}

/* .video-half .banner .btn-play {
  height: 45px;
  bottom: initial;
} */

@media (min-width: 768px) {
  .video-half .row {
    display: table;
  }

  .video-half .col-sm-push-6,
  .video-half .col-sm-pull-6 {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }

  .video-half .col-sm-push-6 {
    background: $white;
  }
}

@media (max-width: 640px) {
  .video-half .mobile-image-holder-wrapper .mobile-image-holder {
    padding-bottom: 0;
  }

  .video-half .banner .holder .description-box {
    padding-top: 20%;
    background: none;
  }
}

.oh-cta-bottom-gray div:last-child h2 {
  font-weight: 600;
}

/* HOME 2017 typography */

@media (min-width: 641px) {
  .static-message h1 {
    font-size: 36px;
    padding-right: 20px;
  }

  .static-message h2 {
    font-size: 26px;
    padding-right: 20px;
  }

  .static-message p {
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .static-message h1 {
    font-size: 38px;
  }

  .static-message h2 {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .static-message h1 {
    font-size: 40px;
  }

  .static-message h2 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .static-message h1 {
    font-size: 42px;
  }

  .static-message h2 {
    font-size: 36px;
  }
}

.home {
  .banner {
    padding: 0 0 50px;
  }

  .banner h1 {
    color: $clr20;
  }

  .banner .row {
    padding: 0 0 10px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    min-height: 50px;
    flex-flow: row wrap;

    @include media('>=tablet') {
      padding: 60px 0;
    }
  }

  .intro-video-holder {
    margin: 15px;
    padding: 0;
    min-height: inherit;

    .icon-play {
      color: $clr20;
      top: 100%;
    }

    .icon-stop {
      width: 30px;
      height: 30px;
    }
  }

  .static-message {
    padding: 0 10%;

    @include media('>=tablet') {
      padding: 0 0 0 5%;
    }
  }

  .video-top {
    padding: 0 15px 20px;

    @include media('>=tablet') {
      padding: 0 5%;
    }

    @include media('>=desktop-2') {
      padding: 0 10% 0 5%;
    }
  }

  .offer-block h3 {
    //  color: white;
  }

  .information-block-icon {
    margin-right: 1em;
    float: left;
    height: 10em;
  }

  .information-block {
    &:first-child {
      .information-block-inner {
        margin-left: 0;
        margin-right: 1em;

        @include media('<tablet-md') {
          margin-left: 0.5em;
          margin-right: 0.5em;
        }
      }
    }

    &:last-child {
      .information-block-inner {
        margin-right: 0;
        margin-left: 1em;

        @include media('<tablet-md') {
          margin-left: 0.5em;
          margin-right: 0.5em;
        }
      }
    }

    .information-block-inner {
      margin: 1em 0.5em;
      box-shadow: 0 0 0.5em lightgray;
    }

    .information-block-info {
      padding: 1em;

      h3 {
        margin-bottom: 0.25em;
      }

      p {
        margin-bottom: 0.5em;
      }
    }

    img {
      width: 100%;
      padding: 0.9em;
    }
  }
}

body {
  &.home-2019 {
    background-color: $white;
  }

  &.menu-active {
    overflow: visible;
  }
}

.home-2019 {
  .block-content {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  main {
    max-width: 100%;
    margin: 0;

    /* autoprefixer grid: autoplace */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    background-color: $white;

    @include media('>=tablet-md') {
      grid-template-columns: 0.5fr 1.5fr 1fr 0.5fr;
      grid-template-areas:
        "alert                 alert                 alert                 alert"
        "message               message               message               message"
        "banner                banner                banner                banner"
        "how-can-we-help       how-can-we-help       how-can-we-help       how-can-we-help"
        "make-it-happen        make-it-happen        make-it-happen        make-it-happen"
        "testimonial           testimonial           testimonial           testimonial"
        "our-students          our-students          our-students          our-students"
        "student-life          student-life          student-life          student-life"
        "discover-our-programs discover-our-programs discover-our-programs discover-our-programs"
        ".                     news                  events                .";

      #alert {
        grid-area: alert;
        width: 100%;
      }

      #message {
        grid-area: message;
        width: 100%;
      }

      #banner {
        grid-area: banner;
      }

      #how-can-we-help {
        grid-area: how-can-we-help;
      }

      #make-it-happen {
        grid-area: make-it-happen;
      }

      #testimonial {
        grid-area: testimonial;
      }

      #our-students {
        grid-area: our-students;
      }

      #student-life {
        grid-area: student-life;
      }

      #discover-our-programs {
        grid-area: discover-our-programs;
      }

      #news {
        grid-area: news;
      }

      #events {
        grid-area: events;
      }
    }
  }

  #banner {
    width: 100%;

    img {
      width: 100%;
    }
  }

  #how-can-we-help {
    width: 100%;
    background-color: $primary-blue;
    color: $white;
    padding: 24px;

    a {
      color: $white;
    }

    h2 {
      font-size: 20px;
      color: $white;
      margin-top: 0;
      font-family: open-sans, sans-serif;
      font-weight: bold;
    }

    .help-box {
      margin: 0 auto;
      width: 50%;

      .help-box-icons {
        width: 44px;
        padding-bottom: 10px;
      }
    }

    .help-box:first-of-type h2 {
      text-align: center;
      padding-bottom: 30px;
    }

    @include media('>=tablet-md') {
      .block-content {
        display: grid;
        grid-template-areas: "how-col1 how-col2 how-col3 how-col4";
        grid-template-columns: 25% 25% 25% 25%;
        grid-template-rows: auto;

        & > .box1 {
          grid-area: how-col1;
        }

        & > .box2 {
          grid-area: how-col2;
        }

        & > .box3 {
          grid-area: how-col3;
        }

        & > .box4 {
          grid-area: how-col4;
        }

        .help-box {
          width: 100%;

          &:first-of-type h2 {
            padding-bottom: 0;
            margin-left: 25%;
            text-align: left;
            position: relative;
            top: 50%;
            transform: perspective(1px) translateY(-50%);
          }

          .help-box-icons {
            width: 44px;
            height: 190px;
            margin-right: 20px;
            float: left;
          }

          .help-box-text {
            ul {
              list-style-type: none;
              padding: 0;
            }
          }
        }
      }
    }
  }

  #make-it-happen {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background: $brown-bg url(../images/home/2019/makeithappen-small.png) no-repeat;
    color: $white;
    background-size: contain;

    .make-box {
      .inner {
        text-align: center;
      }

      h2 {
        font-family: open-sans, sans-serif;
        font-size: 36px;
        color: $white;
        margin-top: 50%;
      }

      h2,
      p {
        text-align: left;
      }

      p {
        font-size: 18px;
      }

      &:last-of-type {
        text-align: right;
      }

      a {
        display: inline-block;
        font-size: 14px;
        padding: 10px;
        border-radius: 10px;
        background: $white;
        width: 200px;
        margin: 4px;
        color: $black;
        text-align: left;

        span {
          color: $primary-blue;
          float: right;
          font-weight: bold;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }

    .white-circle-base {
      display: none;
    }

    .os-torch-icon {
      display: none;
    }

    @include media('>=tablet-md') {
      padding: 60px;
      background: $brown-bg url(../images/home/2019/GettyImages-87755952.png) center no-repeat;
      height: 400px;

      .block-content {
        /* autoprefixer grid: autoplace */
        display: grid;
        grid-template-areas: "make-box1 make-box2";
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;

        .make-box:nth-child(1) {
          grid-area: make-box1;
        }

        .make-box:nth-child(2) {
          grid-area: make-box2;
        }

        .make-box {
          padding: 0 60px;

          a {
            font-size: 22px;
            width: 300px;
          }

          .inner {
            text-align: right;
            position: relative;
            top: 50%;
            transform: perspective(1px) translateY(-50%);
            margin-top: 30px;
            margin-bottom: -30px;
          }

          h2 {
            width: 100%;
            margin-top: 0;
          }

          h2,
          p {
            display: block;
          }

          p {
            font-size: 18px;
            width: 350px;
          }

          a span {
            font-size: 30px;
            line-height: 34px;
          }
        }
      }

      .white-circle-base {
        display: block;
        border-radius: 50%;
        width: 135px;
        height: 135px;
        background-color: $white;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(67px);
      }

      .os-torch-icon {
        display: block;
        transform: translate(29px, 24px);
      }
    }
  }

  #testimonial {
    width: 100%;
    max-width: 1300px;
    margin: 30px auto;
    padding: 0 30px;

    @include media('>=tablet-md') {
      /* autoprefixer grid: autoplace */
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;

      img {
        width: 100%;
        grid-column: 1 / 3;
      }

      blockquote {
        grid-column: 3 / 4;
        width: 362px;
        height: 75%;
        transform: translate(-150px, 25%);
      }

      p {
        grid-column: 4 / 6;
      }
    }

    img {
      width: 100%;
    }

    blockquote {
      width: 100%;
      margin: 30px 0;
      background-color: $blockquote-bg;
      padding: 30px;
      border: none;
      z-index: 5;
      font-family: adelle, serif;
      font-style: italic;
    }

    blockquote cite {
      display: block;
      text-align: right;
    }

    p {
      font-family: open-sans, sans-serif;
      font-size: 2rem;

      @include media('>=tablet-md') {
        padding-top: 10%;
      }

      span {
        text-align: right;
        display: block;
      }

      .read-more {
        display: inline-block;
        font-size: 18px;
        padding: 10px;
        border-radius: 10px;
        background: #00438c;
        width: 175px;
        margin: 4px;
        color: #fff;
        text-align: left;

        .btn {
          display: inline-block;
          font-size: 18px;
          padding: 10px;
          border-radius: 10px;
          background: $primary-blue;
          width: 175px;
          margin: 4px;
          color: $white;
          text-align: left;
          font-family: $font-family-sans-serif-custom;
          font-weight: bold;
          text-transform: none;
        }

        span {
          float: right;
          font-weight: 700;
          font-size: 26px;
          line-height: 28px;
        }
      }
    }
  }

  #our-students {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: $primary-blue;

    h2 {
      padding-top: 30px;
      text-align: center;
    }

    /* Default 2 column layout */
    .our-students-wrapper {
      display: grid;
      grid-template-areas:
        'a a'
        'f f'
        'b c'
        'd e'
        'g h';
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      padding: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    .our-students-a {
      grid-area: a;
    }

    .our-students-b {
      grid-area: b;
    }

    .our-students-c {
      grid-area: c;
    }

    .our-students-d {
      grid-area: d;
    }

    .our-students-e {
      grid-area: e;
    }

    .our-students-f {
      grid-area: f;

      @include media('<phone') {
        img:first-of-type {
          width: 60px !important;
          height: auto !important;
        }
      }
    }

    .our-students-g {
      grid-area: g;
    }

    .our-students-h {
      grid-area: h;
    }

    /* 3 column layout */
    @include media('>=tablet') {
      .our-students-wrapper {
        grid-template-areas: 'a f f' 'b c d' 'e g h';
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
      }

      .our-students-f {
        vertical-align: middle;
      }
    }

    /* 5 column layout */
    @include media('>=widescreen') {
      .our-students-wrapper {
        grid-template-areas: 'a b c f f' 'a d e g h';
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 20px;
      }
    }

    .our-students-box {
      color: $white;
      font-family: $font-family-sans-serif-custom;
      font-weight: 300;
      font-size: 16px;
      line-height: 1.3;
      letter-spacing: 0.05em;

      /* Small screen, center content */
      @include media('<widescreen') {
        text-align: center;
      }
    }

    .our-students-head {
      grid-column: auto / span 10;
      grid-row: 1;
      text-align: center;
      justify-content: center;
    }

    .our-students {
      color: $white;
      font-family: adelle, serif;
      font-weight: bold;
      font-size: 33px;
    }

    .our-students-bold-white {
      color: $white;
      font-family: adelle, serif;
      font-weight: bold;
      font-size: 54px;
      line-height: 0.5;
    }

    .our-students-bold-orange {
      color: $secondary-orange;
      font-family: adelle, serif;
      font-weight: bold;
      font-size: 50px;
      line-height: 1.1;
    }

    .our-students-standard-text {
      color: $white;
      font-family: $font-family-sans-serif-custom;
      font-weight: 300;
      font-size: 16px;
    }

    .our-students-highlight {
      color: $secondary-orange;
      font-family: $font-family-sans-serif-custom;
      font-weight: bold;
      font-size: 16px;
    }

    .our-students-large-highlight {
      color: $secondary-orange;
      font-size: 29px;
      letter-spacing: 0.3em;
    }

    .circle-base {
      border-radius: 50%;
      width: 135px;
      height: 135px;
      background-color: $primary-blue;
      margin-right: auto;
      margin-left: auto;
      transform: translateY(67px);
      margin-top: -67px;
    }

    .os-torch-icon {
      display: block;
      transform: translate(29px, 24px);
    }
  }

  #student-life {
    padding: 80px 0;

    #sl-widescreen {
      max-width: 1300px;
      margin: 0 auto;
      display: none;
    }

    .img-small img {
      max-width: 100% !important;
      height: auto !important;
    }

    #life-tabs {
      a {
        display: block;
        width: 275px;
        height: 50px;
        color: $black;
        text-decoration: none;
        background: $gray-bg;
        text-transform: uppercase;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        margin: 1px auto;
        font-size: 14px;
      }

      .sl-nav-tab.active,
      [aria-expanded="true"] {
        background: $primary-orange;
        text-decoration: underline;
      }
    }

    .normal {
      background: $gray-bg;
    }

    .sl-tab {
      display: none;
      padding: 20px;
    }

    .sl-tab .img-large {
      display: none;
      margin: 0 auto;
    }

    .sl-tab .img-small img {
      margin: 0 auto;
    }

    .sl-tab.active {
      display: grid;
    }

    .sl-tab h2 {
      font-size: 42px;
      font-family: open-sans, sans-serif;
      margin-top: 0;
    }

    .sl-tab .learn-more {
      display: inline-block;
      font-size: 18px;
      padding: 10px;
      border-radius: 10px;
      background: $primary-blue;
      width: 175px;
      margin: 4px;
      color: $white;
      text-align: left;
    }

    .sl-tab .learn-more span {
      float: right;
      font-weight: bold;
      font-size: 26px;
      line-height: 28px;
    }

    .right {
      text-align: right;
      margin: 0;
    }

    @include media('>=tablet-md') {
      #sl-widescreen {
        /* autoprefixer grid: autoplace */
        display: grid;
        grid-template-areas: "sl-col1 sl-col2";
        grid-template-columns: 275px auto;
        grid-template-rows: auto;

        & > div:nth-child(1) {
          grid-area: sl-col1;
        }

        & > div:nth-child(2) {
          grid-area: sl-col2;
        }
      }

      .img-large img {
        max-height: 305px !important;
        width: auto !important;
        margin: 0 auto;
      }

      .sl-tab {
        /* autoprefixer grid: autoplace */
        grid-template-areas: "tab-col1 tab-col2";
        grid-template-columns: 460px auto;
        grid-template-rows: auto;
        padding: 0 0 0 20px;

        & > div:nth-child(1) {
          grid-area: tab-col1;
        }

        & > div:nth-child(2) {
          grid-area: tab-col2;
        }
      }

      .sl-tab .active,
      .sl-tab.collapse.in {
        display: grid !important;
      }

      .sl-tab .img-large {
        margin: 0 auto;
        display: block;
      }

      .sl-tab .img-small {
        display: none;
      }
    }

    /* new code for accordion */
    @include media('>=tablet-md') {
      #student_life_accordion {
        display: none;
      }

      #sl-widescreen {
        display: grid;
      }
    }

    #student_life_accordion .panel-heading {
      display: block;
      width: 275px;
      color: $black;
      text-decoration: none;
      background: $gray-bg !important;
      text-transform: uppercase;
      line-height: 50px;
      text-align: center;
      font-weight: bold;
      margin: 1px auto;
      font-size: 14px;
      border-bottom: none !important;
      padding: 0;
    }

    #student_life_accordion [data-toggle="collapse"]:not(.collapsed) {
      margin-bottom: -30px !important;
      z-index: 1;
      position: relative;
    }

    #student_life_accordion h4 a {
      font-weight: 400;
      font-family: $font-family-sans-serif-custom;
      font-size: 18px;
      text-transform: uppercase;
      padding: 10px 15px;
      display: block;

      &:hover {
        cursor: pointer;
      }

      &:active,
      &:focus {
        color: $black;
      }
    }

    [data-toggle="collapse"]:not(.collapsed) {
      background-color: $primary-orange;
    }

    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      border: 1px solid $gray-border;
      margin: 0 40px 20px;
      padding-top: 30px;
    }
  }

  #discover-our-programs {
    width: 100%;
    background: url(../images/home/2019/graduation-caps.png) center center;
    background-size: cover;
    text-align: center;

    #rbga {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 30px 0;
      min-height: 460px;
    }

    h2 {
      display: inline-block;
      padding: 10px 20px;
      font-weight: bold;
      font-size: 24px;
      margin: 10px 0;
      color: $white;
    }

    h3 {
      color: $white;
      font-family: open-sans, sans-serif;
      margin: 0 0 20px;
      font-weight: bold;
      font-size: 25px;
    }

    h3 a button {
      color: $white;
      background-color: unset;
    }

    h3 button {
      background: none;
      border: none;
    }

    h3 a:hover,
    h3 a:active,
    h3 a:focus,
    h3 button:hover,
    h3 button:active,
    h3 button:focus {
      color: $button-active-blue;
      text-decoration: underline;
    }

    #discover-menu-large {
      display: none;
    }

    @include media('>=tablet') {
      #discover-menu-small {
        display: none;
      }

      #discover-menu-large {
        display: block;
      }
    }

    #programs-cols-undergrad,
    #programs-cols-grad,
    #programs-cols-nursing {
      margin: 0 auto;
    }

    .programs {
      display: block;
      width: 250px;
      height: 50px;
      background: $white;
      margin: 0 auto 15px;
    }

    .programs span {
      display: inline-block;
      background: $primary-orange;
      height: 50px;
      width: 50px;
      text-align: center;
      vertical-align: middle;
    }

    .programs span img {
      width: auto !important;
      height: 30px !important;
      margin-top: 10px;
    }

    .programs span#users img {
      width: 30px !important;
      height: auto !important;
      margin-top: 15px;
    }

    .programs p {
      display: inline-block;
      width: 200px;
      margin: 0;
      font-size: 16px;
      vertical-align: middle;
      line-height: 1;
      padding-left: 10px;
      text-align: left;
    }

    .programs-nav-tab.active {
      color: $button-active-blue;
    }

    .programs-tab.active {
      display: grid;
    }

    .programs a {
      color: $black;
    }

    @include media('>=tablet') {
      h2 {
        font-size: 42px;
      }

      #programs-cols-undergrad,
      #programs-cols-grad,
      #programs-cols-nursing {
        column-count: 2;
        column-gap: 20px;
        max-width: 600px;
        text-align: left;
      }
    }

    @include media('>=tablet-md') {
      #programs-cols-undergrad {
        column-count: 3;
        max-width: 800px;
      }

      #programs-cols-grad,
      #programs-cols-nursing {
        column-count: 1;
        max-width: 800px;
      }

      #programs-cols-grad .three-prog,
      #programs-cols-nursing .three-prog {
        column-count: 3;
      }

      #programs-cols-grad .two-prog,
      #programs-cols-nursing .two-prog {
        column-count: 2;
        max-width: 528px;
      }

      #discover-our-programs h3 {
        font-size: 22px;
      }
    }
  }

  #news,
  #events .event-head {
    a {
      color: $black;
    }

    h3 {
      font-family: adelle, serif;
      display: inline-block;
      margin: 0 0 20px;
    }

    .more {
      margin: 0 0 10px 0;
      float: right;
      font-family: $font-family-sans-serif-custom;
      font-size: 0.8em;
      color: $black;
      line-height: 26px;

      &::after {
        content: " \000BB";
        color: $primary-orange;
      }

      @include media('>=tablet-md') {
        line-height: 36px;
      }
    }
  }

  #news {
    width: 100%;
    max-width: 651px;
    padding: 30px;
    margin: 0 auto;

    @include media('>=tablet-md') {
      padding: 60px 0;
      margin: 0;
    }

    & > p {
      display: inline-block;
      float: right;
      margin: 0;
    }

    .news-holder {
      width: 100%;
      margin: 0 5px;

      /* autoprefixer grid: autoplace */
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    @include media('>=tablet-md') {
      width: 50vw;

      .news-holder {
        width: 100%;
        margin: 0;

        /* autoprefixer grid: autoplace */
        display: grid;
        grid-template-areas: "news-col1 news-col2 news-col3";
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 20px;

        & > .news-item:nth-child(1) {
          grid-area: news-col1;
        }

        & > .news-item:nth-child(2) {
          grid-area: news-col2;
        }

        & > .news-item:nth-child(3) {
          grid-area: news-col3;
        }
      }

      .news-item {
        padding: 0;
        min-height: unset;
      }
    }

    .news-item .visual img {
      border: solid 1px;
    }
  }

  #events {
    width: 100%;
    max-width: 500px;
    padding: 30px;
    margin: 0 auto;

    @include media('>=tablet-md') {
      width: 50vw;
      padding: 60px 0;
      margin: 0;
    }

    a {
      font-weight: bold;
    }

    .event-head {
      padding: 0 10px;

      & > div {
        display: inline-block;
        float: right;
        margin: 0;
      }
    }

    .events-list {
      padding: 0 10%;
      clear: both;

      @include media('>=tablet-md') {
        padding: 0;
      }

      li {
        align-items: flex-start;
        height: 200px;
        padding-top: 0;

        @include media('>=tablet-md') {
          max-width: 250px;
          float: left;
          padding: 0 10px;
        }

        .data {
          @include media('>=widescreen') {
            padding: 0 0 0 24px;
            font-weight: bold;
          }

          em {
            font-style: normal;
            color: $black;
            margin-top: 12px;
            display: inline-block;
            font-weight: normal;
          }
        }

        time {
          display: block;
          width: 100%;
          padding: 5px 15px;
          color: $white;
          background-color: $primary-blue;
          font-family: $font-family-sans-serif-custom;
          font-size: 30px;
          text-align: left;
        }
      }
    }
  }
}
