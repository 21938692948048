@import '../variables/bootstrap-custom';
@import '../helpers/mixins';
@import '../lib/include-media';

body.pl-test-page {
  #wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas: "header1" "sidebar" "header2" "content" "footer1" "footer2";
    margin-top: 64px;

    @include media('>=tablet') {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      grid-template-areas: "header1 header1 header1 header1" "header2 header2 header2 header2" "sidebar content content content" "footer1 footer1 footer1 footer1" "footer2 footer2 footer2 footer2";
      margin-top: 176px;
    }

    & > * {
      min-width: 0;
    }

    .stand-alone-menu {
      grid-area: sidebar;
    }

    main {
      grid-area: content;
    }

    .message-banner {
      grid-area: header1;
    }

    .intro-block {
      grid-area: header2;

      @include media('>=tablet') {
        margin: 0 100px;
      }
    }

    .chance-block {
      grid-area: footer1;
    }

    .bottom-section {
      grid-area: footer2;
    }
  }
}
