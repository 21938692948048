/* Buttons */

@import '../variables/bootstrap-custom';
@import '../helpers/mixins';
@import '../lib/include-media';

.btn {
  white-space: normal;
}

.right-option {
  margin-top: 20px;

  .btn-list.list-unstyled li {
    width: 100%;

    a.btn-stacked {
      width: 200px;

      @include media('>=tablet') {
        width: 100%;
      }
    }
  }
}

/* Adding option for stacked "right hand manu" style buttons */
.btn-stacked {
  margin-bottom: 5px;
  width: 100%;
}

.btn.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;

  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 8px 8px;
    border-radius: 25px;
    font-size: 24px;
    line-height: 1.38;
  }

  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
  }
}

.btn-left {
  position: absolute;
  top: 50%;
  left: -50px;

  @include media('>desktop') {
    left: -75px;
  }
}

.btn-right {
  position: absolute;
  top: 50%;
  right: -50px;

  @include media('>desktop') {
    right: -75px;
  }
}

/* Orange button-style link */
a.btn.btn-secondary {
  color: $black;
  background-color: $primary-orange;
  padding: 3px 12px;
  width: 250px;
  text-transform: none;
}
