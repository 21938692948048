/* Student Connection color fixes */

.highlight-bkg {
  background-color: $clr1;
  color: $white;
  padding: 5px;
  margin: 10px 0;
  overflow: auto;
}

.highlight-bkg a {
  color: $white;
}

.highlight-bkg a:hover {
  color: $clr16;
}
