// custom variables and override bootstrap variables

$fa-font-path: "../fonts";
$icon-font-path: "../fonts/bootstrap/";

$white: #fff;
$black: #000;
$gray-base: #000;
$header-text: $white;
$menu-text: $black;

/* Bootstrap defaults for reference
$brand-primary:         darken(#428bca, 6.5%); // #337ab7
$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;
*/
$brand-danger: #d4423c; // a11y adjustment

$esc-primary: #f7921e; // ($clr20)
$primary-blue: #00438c;
$primary-orange: #f99b1c;
$secondary-orange: #fdb515;
$secondary-blue: #02244a;
$button-active-blue: #1fa9e1;

$body-bg: #fff;
$blockquote-bg: #fce7c2;
$brown-bg: #221409;
$gray-bg: #dcddde;
$gray-border: #ccc;

// iFactory colors used in _header.scss _common.scss
$clr1: #007688; // breadcrumb anchor hover-state; cta-header anchor; cta-header search-opener icon-cross (not working);
$clr2: #6a6c6b; // cta-header anchor hover-state; add-nav ul background; add-nav ul ul background (not used?); news-item
$clr3: #59738e; // #nav ul li anchor - college-wide nav, >=tablet-md
$clr0: #54667c;
$clr4: #f4f4f4; // row.top-panel border-bottom in mid-size layouts
$clr5: #f7f7f7; // #nav ul li anchor background - college-wide nav, >=tablet-md
$clr6: #f1f1f1; // row.nav-holder border - college-wide nav, >=tablet-md
$clr7: #575958; // banner holder description-box background; figure figcaption
$clr8: #00889a; // more
$clr9: #dedede; // guide-line, guide-start, animated-line background
$clr10: #007688; // events-list li::before background
$clr11: #d76f21; // tab (active border: rgba(255, 222, 66, 0.4) ); footer top; shield-bg;
$clr12: #ffde42; // slideshow switcher a::before background
$clr13: #f28b3d; // assessment-block icon
$clr14: #cb6a24; // footer primary, darker
$clr15: #fed84b; // #nav ul li anchor - college-wide nav, <tablet-md; ac-slide slide-holder anchor - college-wide nav active/hover-state; ac-slide featured-col/aid-list:hover/active border/color - college-wide nav featured item; add-nav a:hover
$clr16: #eaeaea;
$clr17: #f3f3f3; // row.nav-holder background - college-wide nav, >=tablet-md
$clr18: #ebebeb; // #nav ul li anchor border - college-wide nav, >=tablet-md
$clr19: #373736; // #nav ul li.active/hover background - college-wide nav hover-state, >=tablet-md
$clr20: #f7921e; // row.breadcrumb-holder background; row.nav-holder background - college-wide nav, <tablet-md; #nav ul li.active/hover border-bottom - college-wide nav hover-state, >=tablet-md; ac-slide slide-holder background/border - college-wide nav, >=tablet-md, hover-state; ac-slide featured-col/aid-list:hover/active background - college-wide nav featured item
$clr21: #9a9b9a; // ac-slide featured-col/aid-list border - college-wide nav featured item
$clr22: #ecebeb;
$clr23: #7d7d7d;

$clr24: #485f7b; // nav-opener::before/after - responsive hamburger menu icon; blockquotes
$clr25: #ffdd5c; // info-links anchors for responsive menu
$clr26: #111; // btn-close (+ hover/active/focus) background
$clr27: #f8f8f8; // events-list background
$clr28: #f7921e; // top-bar (+ add-nav) background, >=tablet-md
$clr29: #686967; // table-box tbody
$clr30: #9c9a9a; // alt-header-banner background
$clr31: #454545; // bottom-section
$clr32: #3f3f3f; // #footer
$clr33: #353736; // (former $clr20) ac-slide featured-col/aid-list a:hover
$clr34: #e6ece9; // homepage offer-block background
$clr35: #ededed; // .light-gray-block

//== Typography
$font-family-sans-serif: open-sans, sans-serif;
$font-family-serif: adelle, serif;
$font-family-sans-serif-custom: transat-text, $font-family-sans-serif;
// $font-family-base:        $font-family-sans-serif !default;

$link-color: $primary-blue;
$link-hover-color: darken($link-color, 15%); // Bootstrap default. For reference.
$link-hover-decoration: underline;

$font-size-base: 14px;

$font-size-h1: 28px; // default: floor(($font-size-base * 2.6)) = 36px
$font-size-h2: 26px; // default: floor(($font-size-base * 2.15)) = 30px
$font-size-h3: 22px; // default: ceil(($font-size-base * 1.7)) = 24px
$font-size-h4: 20px; // default: ceil(($font-size-base * 1.25)) = 18 px
$font-size-h5: 16px; // default: $font-size-base = 14px
$font-size-h6: 14px; // default: ceil(($font-size-base * 0.85)) = 12px

$font-size-menu-opener: 17px;
$font-size-figure-base: 18px;
$font-size-figure-caption: 12px;

$line-height-base: 1.714;

$headings-font-family: $font-family-sans-serif-custom;
$headings-font-family-h1: adelle, $font-family-sans-serif;
$headings-font-weight: 400;
$headings-color: $gray-base;
$gray: lighten($gray-base, 33.5%) !default; // #555
$headings-small-color: $gray;

//== Container sizes
$container-tablet: 623px; // iFactory: 688px / Bootstrap default: 750px (720 + 30px gutter) diff: breakpoint - 18px
$container-desktop: 919px; // iFactory: 925px / Bootstrap default: 970px (940 + 30px gutter) diff: breakpoint - 22px
$container-large-desktop: 1000px; // Bootstrap default: 1170px (1140 + 30px gutter)

//== Media queries breakpoints
$screen-sm: 641px; // Bootstrap default: 768px
$screen-md: 941px; // Bootstrap default: 992px

//== Buttons
$btn-font-weight: 700;
$btn-default-color: $link-color;
$btn-default-bg: $white;
$btn-default-border: transparent;

$btn-primary-color: $white;
$btn-primary-bg: $link-color;
$btn-primary-border: transparent;

//== Breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-color: $gray-base;
