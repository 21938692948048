@import '../helpers/mixins';
@import '../lib/include-media';
@import '../variables/bootstrap-custom';

footer {
  .footer-wrapper {
    /* autoprefixer grid: autoplace */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 20px 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
  }

  .footer-logo {
    grid-column: 1;
    grid-row: 1;
    width: 203px;
    height: 146px;
    background-color: $primary-orange;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
    transform: translateY(-20px);

    img {
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
    }
  }

  .footer-links-1 {
    grid-column: 1;
    grid-row: 3;
    line-height: 25px;
    color: $white;
    font-family: $font-family-sans-serif-custom;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
  }

  .footer-links-2 {
    grid-column: 1;
    grid-row: 4;
    line-height: 25px;
    color: $white;
    font-family: $font-family-sans-serif-custom;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
  }

  .be-ix-link-block {
    grid-column: 1;
    grid-row: 5;
    width: 100%;
    text-align: center;

    .be-label {
      display: inline;
    }

    .be-list {
      list-style: none;
      display: inline-block;
      margin: 0;
      padding-left: 10px;
      vertical-align: bottom;
    }

    .be-list-item {
      list-style: none;
      float: left;
      padding: 0 5px;
    }

    .be-related-link {
      color: $white;
      font-weight: normal;
    }
  }

  .footer-links-3 {
    grid-column: 1;
    grid-row: 6;
    text-align: center;
    padding-top: 10px;

    .address-desktop {
      display: none;

      @include media('>=tablet-md') {
        display: block;
        padding-bottom: 20px;
      }
    }

    .address-mobile {
      @include media('>=tablet-md') {
        display: none;
      }
    }
  }

  .footer-icon-links {
    grid-column: 1;
    grid-row: 7;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-buttons {
    grid-column: 1;
    grid-row: 2;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-div {
    background-color: $primary-blue;
    color: $white;
    font-family: $font-family-sans-serif-custom;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.3;
  }

  .footer-buttons-box a {
    display: inline-block;
    font-size: 17px;
    padding: 10px;
    border-radius: 10px;
    background: $white;
    width: 210px;
    margin: 4px;
    color: $black;
    text-align: left;
    font-weight: 400;
  }

  .footer-buttons-box a span {
    color: $primary-blue;
    float: right;
    font-weight: bold;
    font-size: 17px;
    line-height: 12px;
  }

  .footer-buttons-box {
    padding: 0 30px;

    @include media('>=tablet') {
      padding: 0 60px;
    }
  }

  .footer-social-icon {
    padding-right: 15px;
  }

  .footer-spacer {
    padding-right: 25px;
    white-space: nowrap;
  }

  a.footer {
    color: $white;
    font-family: $font-family-sans-serif-custom;
    font-weight: 300;
    font-size: 16px;
  }

  /* 2 column layout */
  @include media('>=tablet') {
    .footer-wrapper {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    }

    .footer-logo {
      grid-column: 1;
      grid-row: 1;
    }

    .footer-links-1 {
      grid-column: 1;
      grid-row: 2;
    }

    .footer-links-2 {
      grid-column: 2;
      grid-row: 2;
    }

    .be-ix-link-block {
      grid-column: 1 / 3;
      grid-row: 3;
    }

    .footer-links-3 {
      grid-column: 1 / 3;
      grid-row: 4;
    }

    .footer-icon-links {
      grid-column: 1 / 3;
      grid-row: 5;
    }

    .footer-buttons {
      grid-column: 2;
      grid-row: 1;
    }
  }

  /* 4 column layout */
  @include media('>=tablet-md') {
    .footer-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
    }

    .footer-logo {
      grid-column: 1;
      grid-row: 1;
    }

    .footer-links-1 {
      grid-column: 2;
      grid-row: 1;
      text-align: left;
      margin-left: 20px;
    }

    .footer-links-2 {
      grid-column: 3;
      grid-row: 1;
      text-align: left;
      margin-left: 20px;
    }

    .be-ix-link-block {
      grid-column: 1 / 5;
      grid-row: 3;
    }

    .footer-links-3 {
      grid-column: 1 / 5;
      grid-row: 4;
    }

    .footer-icon-links {
      grid-column: 1 / 4;
      grid-row: 5;
    }

    .footer-buttons {
      grid-column: 6;
      grid-row: 1 / 4;
    }
  }

  /* 6 columns layout */
  @include media('>=widescreen') {
    .footer-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
    }

    .footer-logo {
      grid-column: 1;
      grid-row: 1;
    }

    .footer-links-1 {
      grid-column: 2;
      grid-row: 1;
    }

    .footer-links-2 {
      grid-column: 3;
      grid-row: 1;
    }

    .be-ix-link-block {
      grid-column: 1 / 5;
      grid-row: 2;
    }

    .footer-links-3 {
      grid-column: 1 / 5;
      grid-row: 3;
    }

    .footer-icon-links {
      grid-column: 1 / 3;
      grid-row: 4;
    }

    .footer-buttons {
      grid-column: 6;
      grid-row: 1 / 4;
    }
  }
}
