@import '../variables/bootstrap-custom';
@import '../helpers/mixins';
@import '../lib/include-media';

.stand-alone-menu {
  ul {
    padding-left: 10px;
  }

  li {
    line-height: 1;
    padding: 0 1em 0;
  }

  .navigation {
    @include media('>=tablet-md') {
      border-right: 5px solid $clr3;
      max-height: 800px;
      overflow-y: hidden;
      padding-right: 1em;
    }

    a[data-submenu]::after {
      content: "\025bc";
      font-size: 75%;
      position: absolute;
      right: 0;
      padding: 0 1em;
    }
  }

  /* this is the initial state of all submenus.
    we set it to max-height: 0, and hide the overflowed content.
    */

  /* subMenu via T4 menu classes */
  [class^="multilevel-linkul-"] {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: all 1s ease-out;
  }

  .currentbranch0 a {
    @include media('>=tablet-md') {
      display: inline-block;
      font-style: italic;
    }
  }

  .currentbranch0 + [class^="multilevel-linkul-"] {
    display: block;
    max-height: 400px;
    overflow-y: auto;

    a {
      font-weight: normal;
    }

    ::after {
      content: "";
    }

    [class^="currentbranch"] {
      a {
        font-weight: 600;
        font-style: italic;
      }
    }
  }

  .head {
    margin-top: 10px;
    border-bottom: $clr9 1px solid;

    @include media('>=tablet-md') {
      display: none;
    }
  }

  @include media('>=tablet-md') {
    margin: 10px auto;

    & > ul {
      display: block;
    }
  }

  /* https://medium.com/@heyoka/responsive-pure-css-off-canvas-hamburger-menu-aebc8d11d793 */

  /* Screen reader only */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  /* Button styling */
  .menu-toggle {
    display: inline-block;
    padding: 0.75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #333;

    @include media('>=tablet-md') {
      display: none;
    }
  }

  .menu-toggle:hover,
  .menu-toggle:focus {
    color: #c00;
  }

  /*
   Default styles + Mobile first
   Offscreen menu style
  */
  .main-menu {
    position: absolute;
    display: none;
    left: -200px;
    top: 0;
    max-height: 800px;
    overflow-y: auto;
    overflow-x: visible;
    z-index: 999;
    border: 4px solid $link-color;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 0;

    @supports (position: fixed) {
      position: fixed;
    }

    > ul {
      padding: 1.5em 0 0 15px;
    }

    ul {
      list-style: none;
      margin: 0;
      min-height: 100%;
      width: 100%;
      background: $white;

      @include media('>=tablet-md') {
        /* Undo off-canvas styling */
        padding: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: auto;
        width: auto;
        background: none;
      }
    }

    a {
      display: block;
      padding: 0.5em 15px;
      line-height: 1em;
      font-size: 1em;
      color: $link-color;
      text-decoration: none;

      @include media('>=tablet-md') {
        border: 0 !important; /* Remove borders from off-canvas styling */
        padding: 0.5em;
      }
    }

    a:hover,
    a:focus {
      text-decoration: underline;

      @include media('>=tablet-md') {
        background: none; /* Remove background from off-canvas styling */
      }
    }

    .menu-close {
      position: absolute;
      right: 5px;
      top: 5px;

      @include media('>=tablet-md') {
        display: none;
      }
    }

    /*
     On small devices, allow it to toggle...
    */

    @include media('<tablet-md') {
      /*
       :target for non-JavaScript
         [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
        */
      &:target,
      &[aria-hidden="false"] {
        display: block;
        top: 110px;
        left: 0;
        outline: none;
      }

      &:target .menu-close,
      &[aria-hidden="false"] .menu-close {
        z-index: 1001;
      }

      &:target ul,
      &[aria-hidden="false"] ul {
        position: relative;
        z-index: 1000;
      }

      /*
       We could us `.main-menu:target:after`, but
       it wouldn't be clickable.
      */
      &:target + .backdrop,
      &[aria-hidden="false"] + .backdrop {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 998;
        background: #000;
        background: rgba(0, 0, 0, 0.85);
        cursor: default;

        @supports (position: fixed) {
          position: fixed;
        }
      }
    }

    [aria-hidden="true"] {
      display: none;
    }

    [aria-hidden="false"] {
      display: block;
    }

    @include media('>=tablet-md') {
      /* Undo positioning of off-canvas menu */
      position: relative;
      left: auto;
      top: auto;
      // height: 100%;
      display: block;
      border: none;
      border-radius: unset;
    }
  }

  #apply-request-block {
    display: none;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 2em;
    padding: 0 10px;

    @include media('>=tablet-md') {
      display: block;
    }

    @include media('>=desktop-2') {
      width: 75%;
    }
  }
}

.btn-esc-menu-horiz {
  border: $link-color 2px solid;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-size: 1.4rem;
  width: 230px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px;
  background-color: $link-color;
  color: $white;
}
