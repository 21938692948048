/* @import '../variables/bootstrap-custom'; */

/* BELOW legacy contextual stuff to phase out or move to a content type or otherwise unique css */

.img-border img {
  border: 1px solid $clr9;
}

/* list styles
---------------------------------------------------------------------------------- */
.topnews li {
  margin-top: 0.8rem;
  padding-top: 0.8rem;
}

ul.plain {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}

ul.plain li {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
}

.upper-alpha {
  list-style-type: upper-alpha;
}

.lower-alpha {
  list-style-type: lower-alpha;
}

.upper-roman {
  list-style-type: upper-roman;
}

.lower-roman {
  list-style-type: lower-roman;
}

/* follow block
---------------------------------------------------------------------------------- */
.follow-block {
  margin-bottom: 20px;
}

.follow-block a {
  margin: 4px;
  border-bottom: 0;
}

.iterator a {
  display: block;
  padding: 2px 6px;
  border: 1px solid $clr21;
  font-size: 90%;
}

ul.inside > li,
ol.inside > li {
  list-style-position: inside;
}

.inside ul,
.inside ol {
  list-style-position: inside;
}

.inside li a {
  margin-left: -8px;
}

/* \0/ a CSS hack that makes that declaration ignored by everything but IE8 and IE9 -- throws an error, but KW is willing to live with it */
ul.list-float-fix > li,
ol.list-float-fix > li {
  list-style-position: inside;
}

.list-float-fix ul,
.list-float-fix ol {
  list-style-position: inside;
}

.list-float-fix li a {
  margin-left: -8px;
}

/* \0/ a CSS hack that makes that declaration ignored by everything but IE8 and IE9 -- throws an error, but KW is willing to live with it */
#alert.ui-state-error {
  padding: 1em 2em;
}

#alert .ui-icon {
  top: 13px;
  position: relative;
}

/* reminder, z-index in main containers breaks direct edit */

/* tables */
.table-th-center th,
.table-td-center td {
  text-align: center;
}

table.tight th,
table.tight td {
  padding: 0;
}

th.plain {
  background-color: $white;
}

table.plain.policy th {
  padding: 0 2em 1rem 0;
  text-align: left;
  background-color: $white;
}

.faux-table-border-bottom h2 {
  background-color: $clr9;
  padding: 5px;
  margin: 10px 0 0;
  overflow: auto;
}

.topnews h1,
.topnews h2 {
  padding-bottom: 0;
}

.topnews h2 {
  padding-top: 0;
  font-size: 140%;
}

.topnews.feature h2 {
  font-size: 160%;
}

.block {
  border: 1px solid $clr21;
  padding: 8px;
  margin-bottom: 15px;
  background: $clr17;
}

.block > h2:first-of-type {
  padding-top: 0.3em;
}

.block div > h2:first-of-type {
  padding-top: 0.3em;
}

table.plain.policy th h2 {
  padding: 0;
  margin: 0 0 1rem;
}

.faux-table-border-bottom {
  margin-top: 20px;
}

.faux-table-border-bottom .col-sm-12 {
  border-bottom: 1px solid $clr9;
  padding-top: 10px;
  padding-bottom: 20px;
  background-color: $clr18;
}

.faux-tbody {
  background-color: $white;
  padding-top: 5px;
}

.faux-table-border-bottom .faux-tbody {
  padding: 10px;
}

.faux-thead h3 {
  font-size: 130%;
  font-weight: 600;
}

.faux-tbody h4 {
  font-size: 120%;
  font-weight: 600;
}

.faux-thead {
  background-color: $clr18;
  padding-top: 5px;
}

h4.faux-thead {
  margin-top: 2rem;
  padding: 0.5rem;
}

/* misc classes
------------------------------------------------------------------------ */

/* don't use - NEED TO PHASE OUT */
.right {
  float: right;
}

.left {
  float: left;
}

/* end don't use */

/* don't use - use .well instead */
.highlight-border {
  border: 2px solid $clr18;
  padding: 5px;
  margin: 0.7em 0;
  overflow: auto;
}

/* end don't use */

.highlight {
  color: $clr7;
  font-weight: 700;
}

.float-right,
.float-left {
  float: none;
  margin: 0;
}

@media (min-width: 641px) {
  .float-right {
    float: right;
    margin-left: 10px;
    margin-right: 0;
  }

  .float-left {
    float: left;
    margin-right: 10px;
    margin-left: 0;
  }
}

.center {
  text-align: center;
}

.clear-right {
  clear: right;
}

.clear-left {
  clear: left;
}

.clear-both {
  clear: both;
}

.pad {
  padding: 10px;
}

.pad-top {
  padding-top: 1.5em;
}

.pad-top-more {
  padding-top: 2em;
}

.pad-bottom {
  padding-bottom: 1.5em;
}

.pad-h {
  padding-left: 10px;
  padding-right: 10px;
}

.pad-left {
  padding-left: 30px;
}

.pad-right {
  padding-right: 30px;
}

.tight {
  margin: 0;
  padding: 0;
}

.notice {
  color: $brand-danger;
}

.smaller {
  font-size: 0.8em;
  line-height: 1.3;
}

.large {
  font-size: 1.3em;
}

.black {
  color: $gray-base;
}

.border-top {
  border-top: 1px solid $clr21;
  padding-top: 3px;
  margin-top: 3px;
}

.border-bottom {
  border-top: 1px solid $clr13;
  padding-top: 6px;
  margin-top: 6px;
}

.divider-top {
  border-top: 1px solid $clr7;
  margin-top: 20px;
  padding-top: 10px;
}

.nobr {
  white-space: nowrap;
}

/* classes not for the editor */
.valign {
  vertical-align: middle;
}

.valign-top {
  vertical-align: top;
}

.valign-bottom {
  vertical-align: bottom;
}

.display-block {
  display: block;
}

.boxover {
  height: 300px;
  overflow-y: scroll;
}

.show {
  display: block;
}

.img-text {
  margin-top: -3px;
  top: 4px;
  position: relative;
}

.hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.hide {
  left: -9999em;
  position: absolute;
}

.show-normal {
  left: 0;
  position: relative;
}

.show-normal .hide {
  left: 0;
  position: relative;
}

/*    Clear Floated Elements, from http://sonspring.com/journal/clearing-floats */
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

/* http://perishablepress.com/press/2009/12/06/new-clearfix-hack */

/* new clearfix */
.clearfix::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/* Non-valid CSS.
* html .clearfix {
  zoom: 1;
}
*/

/* IE6
*:first-child + html .clearfix {
  zoom: 1;
} */

/* IE7 */

/* the following two are from haslayout.net/css/Incorrect-Float-Shrink-Wrap-Bug#bug-affected-versions */

/*
.ie_fix {
  display: inline-block;
}

.ie_fix {
  display: block;
}
*/

/* -------------------------------------------------------------------------------
   some styles for specific content types or templates
---------------------------------------------------------------------------------- */
p.intro {
  font-size: 1.3em;
  color: $clr7;
}

.media-left,
.media-right,
.media-body {
  color: $clr7;
}

.quote {
  color: $clr7;
  font-style: italic;
  border-top: 1px solid $clr21;
  border-bottom: 1px solid $clr21;
  padding: 10px 20px;
}

.quote .attribute {
  font-size: 0.8em;
  padding-left: 40px;
  padding-right: 20px;
}

.top {
  padding-bottom: 20px;
}

.bottom {
  padding-top: 20px;
}

.topnews {
  border-bottom: 1px solid $clr9;
  margin: 30px 0 15px;
  overflow: auto;
  padding: 0;
  position: relative;
  width: 100%;
  clear: left;
}

div.topnews hr {
  display: block;
  clear: left;
  visibility: hidden;
}

/* keep for float clearing */
.news h4,
.news h3 {
  margin-top: 10px;
  padding: 0;
}

.date {
  font-size: 0.8em;
  font-style: italic;
  color: $gray;
  margin: 0.4rem 0;
}

.media-box-inner .date {
  color: $white;
}

.post.topnews {
  border-bottom: 0;
}

.topnews h3 {
  font-size: 130%;
  margin-top: 0;
}

.iterator .next a::after {
  content: " >";
}

.topnews h2 a:link::after,
.topnews h3 a:link::after {
  content: "";
}

.topnews.date {
  padding-bottom: 6px;
}

.topcontent {
  border-top: 1px solid $clr21;
  margin: 0;
  padding: 0 0 10px;
  position: relative;
  width: 100%;
}

.topcontent h4 {
  padding: 0.2em 0;
  font-size: 110%;
}

.morelink {
  white-space: nowrap;
  font-size: 0.8em;
}

.sorted .topnews {
  margin-top: 1.5em;
  font-size: 120%;
}

/* ``````````````````````NEW -- images, image containers, captions`````````````````````` */
.image-area p {
  color: $clr7;
  font-size: 0.8em;
  margin: 0.4rem 0;
}

.img-cap-div {
  margin: 0 10px 10px;
}

.img-cap-div p {
  color: $clr7;
  font-size: 0.8em;
  margin: 0.4rem 0;
}

.img-cap-div.float-right {
  margin-right: 0;
}

.img-cap-div.float-left {
  margin-left: 0;
}

.img-responsive.text-center {
  display: inline;
}

.img-border {
  border: 1px solid $clr9;
}

/* done dealing with images */

/* ``````````````````````panels, wells`````````````````````` */
.panel {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.panel-heading {
  border-bottom: 3px solid $clr11;

  /*	margin-bottom: .75em; */
}

.panel-title {
  margin-bottom: 0;
  font-size: 24px;
}

.well {
  box-shadow: none;
  padding: 10px 20px;
  background-color: $white;
}

.well p:last-child {
  margin: 0;
}

@media (min-width: 641px) {
  .panel-title {
    font-size: 26px;
  }
}

@media (min-width: 941px) {
  .panel-title {
    font-size: 32px;
  }
}

/* JH: ADD ABOVE to appropriate media section later! */

/* ``````````````````````images, image containers, captions`````````````````````` */

img.right,
img.float-right {
  margin-left: 20px;
}

img.left,
img.float-left {
  margin-right: 20px;
}

.image-area.clear {
  margin: 0;
  padding: 0;
}

.image-area {
  margin: 0 10px 10px;
}

.image-area .right,
.image-area.right,
.image-area .float-right,
.image-area.float-right {
  margin-right: 0;
}

.image-area .left,
.image-area.left,
.image-area .float-left,
.image-area.float-left {
  margin-left: 0;
}

/* -------------------------------------------------------------------------------
   some styles for specific content types or templates to follow
---------------------------------------------------------------------------------- */

/* special link styles, not the local or main navigation
---------------------------------------------------------------------------------- */

/* ul.link-list {
	list-style-image:none;
	list-style-type:none;
	padding: 0;
	margin: 0 0 20px;
}
ul.link-list li {
	list-style-image:none;
	list-style-type:none;
	margin: 0;
	padding: 0;
	border:1px dashed #aaaaaa;
	margin-top: -1px;
}
ul.link-list a {
	margin: 1px;
	padding: 5px 5px 5px 15px;
	display: block;
	text-decoration: none;
	background: $white;
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff6f5f4');
	background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f6f5f4));
	background: -moz-linear-gradient(top, $white, #f6f5f4);
	border:0;
  font-weight: 400;
}
ul.link-list a:hover {
	background: #f6f5f4;
 filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff6f5f4', endColorstr='#ffffffff');
	background: -webkit-gradient(linear, left top, left bottom, from(#f6f5f4), to(#fff));
	background: -moz-linear-gradient(top, #f6f5f4, $white);
}
ul.link-list .bigger {
	text-align: center;
}
ul.link-list .bigger a {
	padding: 20px 0;
} */

/* Above should be/will be phased out JH */

/* other styled lists
--------------------------------------------------------------------------------- */

ul.spacev li {
  margin-top: 0.3em;
  padding-top: 0.4em;
}

ul.spacey {
  margin: 5px;
  padding: 0 5px 0 30px;
}

ul.spacey li {
  padding: 0.2em 3px;
  margin: 0.2em 5px 0.2em 35px;
}

ul.spacey li h4 {
  padding: 0.2em 3px;
  margin: 0.2em 5px 0.2em 3px;
}

ul.liststyle1 {
  margin: 10px 0 15px;
  padding: 0 0 0 20px;
}

ul.liststyle1 li {
  margin: 0 0 8px;
  padding: 0 5px 8px;
  border-bottom: 1px dashed $clr30;
}

ul.liststyle1 li:first-child {
  padding-top: 5px;
  border-top: 1px dashed $clr30;
}

.liststyle1 h3 {
  color: $clr7;
  font-weight: 400;
  font-size: 100%;
  line-height: 1.5;
}

.liststyle1 h3 strong {
  font-weight: 800;
  font-size: 110%;
}

ul.tightlist {
  padding: 0;
}

ul.tight {
  padding-left: 2rem;
}

.block > h4:first-of-type {
  padding-top: 0.3em;
}

/* ordered content */
.iterator {
  margin: 40px 0 0;
  width: 100%;
}

.iterator .previous {
  float: left;
}

.iterator .next {
  float: right;
  text-align: right;
}

.iterator a:hover {
  border: 1px solid $clr8;
  font-size: 90%;
}

.iterator .previous a::before {
  content: "< Previous: ";
}

.iterator .next a::before {
  content: "Next: ";
}
