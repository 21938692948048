@import '../helpers/mixins';
@import '../lib/include-media';

/* Toggle Expandable Content */

.expand_wrapper {
  background: none repeat scroll 0 0 $white;
  padding: 0 0 1px;
}

h3.toggle.more {
  background: none;
  font-size: 100%;
  padding-left: 0;
}

div.toggle {
  padding: 10px;
}

div.toggle.more {
  padding: 0;
}

.ui-button {
  padding: 1px 6px;
}

button::-moz-focus-inner {
  padding: 0;
}

/* fix the weird Firefox button padding: */

/* New Toggle look and colors */
h3.toggle {
  font-size: 100%;
  line-height: 1.4;
  margin: 1px 0 0;
  padding: 10px 10px 9px;
  position: relative;
  background: $clr5 none repeat scroll 0 0;
  border: 1px solid $clr3;
}

h3.toggle a {
  color: $clr3;
  background: none;
  padding-left: 5px;
  font-weight: 600;
  display: block;
}

h3.toggle a:hover {
  color: $clr19;
  background: none;
}

h3.toggle > a::before {
  content: "-";
  padding-right: 11px;
  font-size: 22px;
}

h3.toggle > a.collapsed::before {
  content: "+";
  padding-right: 6px;
  font-size: 22px;
}

h3.toggle > a:hover,
h3.toggle > a:active {
  text-decoration: none;
  outline: 0 !important;
}

h3.toggle > a:focus {
  text-decoration: none;
}
