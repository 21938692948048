/* @import '../variables/bootstrap-custom'; */
@import '../helpers/mixins';
@import '../lib/include-media';

.bottom-section {
  background: $clr31;
  position: relative;
  overflow: hidden;
  padding-top: 42px;
  color: $white;

  @include font(14, 20, 700);

  @include media('>=tablet') {
    @include fontsize(16, 20);

    padding-top: 63px;
  }

  @include media('>=tablet-md') {
    padding-top: 70px;
  }

  a {
    font-weight: 700;
    color: $white;
    transition: color 0.25s linear;

    &:hover {
      color: $body-bg;
    }
  }

  .container-holder {
    max-width: 1000px;
  }
}
