@import '../helpers/mixins';
@import '../lib/include-media';

.container .jumbotron {
  background: #ee8d00 none;

  /* *zoom: 1; // IE 5.5-7 Only */
  z-index: 10;
  border: 0;
  padding: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.container-fluid .jumbotron {
  border-radius: 0;
}

.jumbotron .h1 {
  font-size: 200%;
  padding: 15px;
  top: 15px;
  color: #fff;
}

@media (min-width: 400px) and (max-width: 767px) {
  .container .jumbotron {
    background: url('../images/767_Background_Texture_Master.jpg') no-repeat 0 0;
    background-size: cover;
    height: 106px;
    min-height: 106px;
  }
}

@media (min-width: 768px) {
  .container .jumbotron {
    background: url('../images/991_Background_Texture_Master.jpg') no-repeat 0 0;
    background-size: cover;
  }
}

@media (min-width: 992px) {
  .jumbotron .h1 {
    top: 25px;
  }

  .container .jumbotron {
    background: url('../images/1199_Background_Texture_Master.jpg') no-repeat 0 0;
    background-size: cover;
  }
}

@media (min-width: 1200px) {
  .container .jumbotron {
    background: url('../images/1199_Background_Texture_Master.jpg') no-repeat 0 0;
    background-size: cover;
  }
}

/* MyESC */

.container-fluid.esc-primary {
  background: $clr31;
}
