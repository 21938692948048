/* Fixes to styles for learn-more summer */

.striped a {
  color: $clr3;
}

.striped a:hover {
  color: $clr10;
}

.thumbnail > img,
.thumbnail a > img,
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto !important;
}

@media (min-width: 768px) {
  .table-cell {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }

  .table-cell img {
    width: 100% !important;
  }
}

ul.striped.striped-alt-sm li {
  background: $clr5;
}

ul.striped.striped-alt-sm li:nth-child(odd) {
  background: $clr18;
}

@media (min-width: 641px) {
  .esc-lm-content h2 {
    font-size: 32px;
  }

  .esc-lm-content {
    font-size: 14px;
  }

  .esc-lm-content .row .container .container-fluid div:nth-child(5) {
    border-left: 1px solid $clr9;
    margin-bottom: -30px;
  }
}

@media (min-width: 768px) {
  .ct-SunyEscAdvantage .pull-right {
    padding: 0 10px !important;
  }

  .esc-lm-content h2 {
    font-size: 20px;
  }

  .ct-SunyEscAdvantage_ImageBackground-bgr3 {
    background-position: 30% !important;
  }
}

@media (min-width: 991px) {
  .esc-lm-content h2 {
    font-size: 34px;
  }

  .esc-lm-content {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .esc-lm-content .ct-SunyEscAdvantage_ImageBackground-bgr1,
  .esc-lm-content .ct-SunyEscAdvantage_ImageBackground-bgr3 {
    background-size: 100%;
  }
}
