// Libraries and helpers
@import '../lib/include-media';
@import '../lib/bootstrap/variables';
@import '../lib/bootstrap/mixins';
@import '../helpers/helpers';
@import '../helpers/mixins';

// Variables
@import '../variables/bootstrap-custom';
@import '../variables/breakpoints';
@import '../variables/media-expressions';

.emergency-banner {
  background: $clr15;
  overflow: hidden;
  padding-top: 28px;
  padding-bottom: 28px;

  @include media('<=tablet') {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.emergency-major {
    background: $brand-danger;
  }

  &.emergency-info {
    background: $alert-info-bg;
  }

  .row {
    padding: 0 15px;

    @include media('<=tablet-md') {
      padding: 0;
    }
  }

  .emergency-banner-inner {
    display: table;
    width: 100%;
    padding-left: 100px;
    max-width: 1600px;
    margin: 0 auto;
    position: relative;

    &::before {
      content: "";
      background: url(/images/icons/alert@2x.png) center/contain no-repeat;
      width: 53px;
      height: 46px;
      top: 50%;
      left: 25px;
      margin-top: -23px;
      position: absolute;

      @include media('<=tablet-md') {
        left: 15px;
      }

      @include media('<=tablet') {
        width: 28px;
        height: 24px;
        margin-top: 0;
        top: 4px;
      }
    }

    @include media('<=tablet-md') {
      padding-left: 80px;
    }

    @include media('<=tablet') {
      padding-left: 50px;
      display: block;
    }

    .text,
    .btn-wrapper {
      display: table-cell;
      vertical-align: middle;

      @include media('<=tablet') {
        display: block;
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.3;

    &:last-child {
      margin-bottom: 0;
    }

    @include media('<=tablet-md') {
      font-size: 18px;
    }

    @include media('<=tablet') {
      font-size: 14px;

      &:last-child {
        margin: 0 0 11.5px;
      }
    }
  }

  .btn {
    color: black;
    border: 3px solid black;
    font-size: 12px;
    background: transparent;
    padding: 8px 15px;
    float: right;
    margin-left: 50px;

    @include media('<=tablet') {
      float: none;
      margin-left: 0;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .btn-primary:active:hover,
  .btn-primary:active:focus,
  .btn-primary:active.focus,
  .btn-primary.active:hover,
  .btn-primary.active:focus,
  .btn-primary.active.focus,
  .open > .btn-primary.dropdown-toggle:hover,
  .open > .btn-primary.dropdown-toggle:focus,
  .open > .btn-primary.dropdown-toggle.focus {
    color: black;
    background: $body-bg;
    border-color: black;
  }
}
