@import '../variables/bootstrap-custom';
@import '../helpers/mixins';
@import '../lib/include-media';

body.pl-key-page {
  #wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "header" "content" "footer";
    width: 100%;

    @include media('>=tablet') {
      grid-gap: 10px;
      grid-template-columns: 0.25fr minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 0.25fr;
      grid-template-areas: "header header  header  header header" ". content content content ." "footer footer  footer  footer footer";
    }

    & > * {
      min-width: 0;
    }

    #main {
      padding-top: unset;
    }

    main {
      grid-area: content;
      padding: 1rem;
    }

    .message-banner {
      grid-area: header;
    }

    .bottom-section {
      grid-area: footer;
    }
  }

  /*
  .banner {
    z-index: 0;
    h1 {
      line-height: 36px;
    }

    &.intro-block {
      margin-bottom: 0;

      .holder {
        padding: 20px 0 10px;
      }

      h1 {
        margin: 0;
      }
    }
  }
  */
}
