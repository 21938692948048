@import '../helpers/mixins';
@import '../lib/include-media';

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='number'],
  //input[type='url'],
  // input[type='date'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  box-sizing: border-box;
}

select {
  -webkit-border-radius: 0;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
}

.search-drop {
  position: absolute;
  transition: all 0.35s ease-out;
  overflow-x: hidden;
  overflow-y: auto;
  height: 0;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba($clr20, 0.95);

  @include media('>=tablet') {
    top: -6px;
    right: 44px;
    left: auto;
    opacity: 0;
    visibility: hidden;
    background: none;
    height: auto;
    overflow: hidden;
  }

  @include media('>=tablet-md') {
    top: 0;
    right: 0;
  }

  @include media('<tablet') {
    height: calc(100vh - 60px);
    visibility: hidden;
    opacity: 0;
    transition: none;
  }

  .search-active & {
    height: calc(100vh - 60px);

    @include media('>=tablet') {
      opacity: 1;
      visibility: visible;
      height: auto;
    }

    @include media('<tablet') {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.2s;
    }
  }
}

/* search form styles */

.search-form {
  transition: all 0.35s ease-out;
  transform: translateY(-100%);
  padding: 40px 20px 100px;
  height: 100%;

  @include media('>=tablet') {
    padding: 0 0 0 55px;
    height: auto;
    transform: translateY(0);
  }

  @include media('>=tablet-md') {
    padding: 0 0 0 60px;
  }

  .search-close {
    @include media('>=tablet') {
      position: absolute;
      top: 50%;
      left: 0;
      margin: 1px 0 0;
      transform: translateY(-50%);
      display: block;

      @include font(14, 18, 600);

      color: $clr23;
      transition: 0.25s linear;
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    @include media('>=tablet-md') {
      margin: 3px 0 0;
    }

    &.btn-close {
      transform: translateX(-50%);
      bottom: 25px;

      @include media('>=tablet') {
        text-align: left;
        width: auto;
        background: none;
        padding: 0;
        transform: translateY(-50%);
        bottom: auto;
        visibility: visible;
      }
    }
  }

  .search-active & {
    transform: translateY(0);

    @include media('>=tablet') {
      transform: translateX(0);
    }

    .search-close {
      &.btn-close {
        @include media('<tablet') {
          transition-delay: 300ms, 300ms;
          opacity: 1;
        }
      }
    }
  }

  .input-wrap {
    position: relative;

    @include media('>=tablet') {
      width: 280px;
    }

    @include media('>=768px') {
      width: 338px;
    }

    @include media('>=tablet-md') {
      width: 500px;
    }
  }

  input[type='text'],
  input[type='search'] {
    background: $white;

    @include size(100%, 52px);

    border: 2px solid $clr22;
    border-radius: 1px;
    outline: none;
    box-shadow: none;

    @include font(16, 26, 600);

    color: $gray-base;
    padding: 11px 60px 11px 12px;

    @include media('>=tablet', '<tablet-md') {
      @include fontsize(16, 23);

      padding: 10px 55px 10px 18px;
      height: 47px;
    }

    @include media('>=tablet-md') {
      height: 30px;
      padding: 1px 60px 2px 8px;
    }

    @include placeholder {
      color: $gray-base;
    }
  }

  button[type='submit'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 60px;
    border: none;
    background: none;
    padding: 4px;
    color: $clr28;

    &:focus {
      color: $clr1 !important;
    }

    transition: color 0.25s linear;

    @include media('>=tablet') {
      width: 55px;
    }

    &:hover {
      color: $clr2;
    }
  }

  .icon-search {
    @include vertical();

    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    transform: translate(-50%, -50%);
  }
}
