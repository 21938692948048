/* @import '../variables/bootstrap-custom'; */
@import '../helpers/mixins';
@import '../lib/include-media';

.blockquote a {
  color: $white;
  font-weight: 700;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
