/* @import '../variables/bootstrap-custom'; */
@import '../helpers/mixins';
@import '../lib/include-media';

/* ---------------------------------------------------------
   1. General reset
   --------------------------------------------------------- */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  min-width: 320px;
  line-height: 1.6;
  margin: 0;

  @include media('>=tablet') {
    font-size: 16px;
  }

  @include media('>=tablet-md') {
    font-size: 18px;
    line-height: 1.7;
  }

  &.nav-active {
    @include media('<tablet-md') {
      overflow: hidden;
    }
  }

  &.search-active {
    @include media('<tablet') {
      overflow: hidden;
    }
  }
}

.visual {
  img {
    @include img-styles();
  }
}

iframe,
audio,
video {
  display: block;
  width: 100%;
  height: auto;
}

p {
  margin: 0 0 15px;
}

a {
  text-decoration: none;
  font-weight: 700;
}

strong a:link,
.highlight a:link {
  text-decoration: underline;
}

.heading-link a:link::after {
  content: "&nbsp;&raquo;";
}

dt a {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5em;
  text-transform: capitalize;

  a {
    font-weight: 700;
    color: $link-color;
    transition: color 0.25s linear;
  }
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: underline;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: $link-hover-color;
}

h1 {
  font-family: $headings-font-family-h1;
  text-transform: none;
  font-weight: 700;

  @include media('>=tablet') {
    font-size: 50px;
  }

  @include media('>=tablet-md') {
    font-size: 68px;
  }
}

h2 {
  @include media('>=tablet') {
    font-size: 36px;
  }

  @include media('>=tablet-md') {
    font-size: 44px;
  }
}

h3 {
  @include media('>=tablet') {
    font-size: 24px;
  }

  @include media('>=tablet-md') {
    font-size: 36px;
  }
}

h4 {
  @include media('>=tablet') {
    font-size: 27px;
  }
}

h5 {
  @include media('>=tablet') {
    font-size: 18px;
  }

  @include media('>=tablet-md') {
    font-size: 22px;
  }
}

h6 {
  font-weight: 700;
  text-transform: uppercase;
}
