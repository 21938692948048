/* Moved from _common.scss for clarity and ability to exclude when not needed */

@import '../helpers/mixins';
@import '../lib/include-media';
@import '../variables/bootstrap-custom';

.locations-search {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0 20px;
  position: relative;
  color: $white;

  @include media('<=tablet-md') {
    padding: 40px 0 20px;
  }

  @include media('<=tablet') {
    padding: 40px 0 10px;
  }

  .locations-search-field {
    display: none;
  }

  h4 {
    color: $white;
    margin-bottom: 30px;
  }

  .container {
    position: relative;
    z-index: 1;

    @include media('<=tablet-md') {
      max-width: none;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include media('<=tablet') {
      max-width: none;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(215, 111, 32, 0.85);
  }

  .btn {
    color: $white;

    .icon-locations {
      width: 22px;
      height: 22px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      margin-right: 9px;
      background: url(/images/icons/show-all@2x.png) 100%/contain no-repeat;
    }
  }

  .icon-up {
    width: 16px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-left: 5px;
    top: -1px;
    background: url(/images/icons/collapse-white@2x.png) 100%/contain no-repeat;
  }

  .btn-list {
    margin: 60px 0;

    @include btn-list-tablet();

    @include media('<=tablet-md') {
      margin: 40px 0;
    }

    @include media('<=tablet') {
      margin: 20px 0 10px;
    }

    li {
      padding: 0 10px 12px;
    }

    .btn {
      background: $white;
      color: $clr11;
    }

    &.list-bottom {
      margin: 0;

      .btn {
        margin: 0;
        background: transparent;
        color: $white;
      }

      li {
        @include media('<=tablet') {
          padding: 0 10px 5px;
        }
      }
    }
  }

  hr {
    height: 1px;
    background: $white;

    @include media('<=tablet-md') {
      margin-bottom: 0;
    }

    @include media('<=tablet') {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  input[type="search"] {
    background: $white;
    color: $clr11;
  }

  .form-label {
    font-size: 14px;
    display: block;
    clear: both;
    text-align: left;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .checkbox-accordion {
    &.active {
      .form-label {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .checkboxes {
    overflow: hidden;
    width: 100%;

    .checkbox-wrapper {
      font-weight: 500;
      margin-bottom: 10px;
      font-size: 16px;
      width: 35%;
      display: inline-block;
      float: left;
      text-align: left;

      @include media('>=tablet') {
        &:nth-child(3) {
          width: 30%;
        }
      }

      @include media('<=tablet-md') {
        margin-bottom: 5px;
      }

      @include media('<=tablet') {
        width: 100%;
        float: none;
        display: block;
      }

      label {
        font-weight: 500;
        position: relative;
        line-height: 1.3;
      }

      input {
        position: absolute;
        opacity: 0;

        &:checked + .check::before {
          background-image: url(/images/icons/check-on@2x.png);
        }
      }

      .check {
        padding-left: 30px;
        display: block;
        position: relative;

        &::before {
          content: "";
          width: 15px;
          height: 15px;
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          top: 5px;
          left: 0;
          background: url(/images/icons/check-off@2x.png) center/100% no-repeat;

          @include media('<=tablet') {
            top: 6px;
          }
        }
      }
    }
  }

  .btn-search {
    background: $white;
    color: $clr11;
    margin-bottom: 20px;

    &:hover {
      background: $body-bg;
    }

    .icon {
      margin-left: 5px;
    }

    @include media('<=tablet') {
      width: 100%;
    }
  }

  .text-input {
    width: 69%;

    @include media('<=tablet') {
      width: 100%;
    }

    label,
    input {
      max-width: none;
      width: 100%;
    }

    input {
      color: black;
    }
  }

  .select-input {
    width: 30%;
    float: left;

    @include media('<=tablet') {
      float: none;
      width: 70%;
      margin-bottom: 15px;
    }

    label,
    select {
      width: 100%;
    }

    select {
      height: 54px;
      -moz-appearance: none;
      -webkit-appearance: none;
      padding: 12px;
      margin: 6px 0;
      border: 1px solid $clr21;
      border-radius: 3px;
      color: $clr8;
      font-weight: 600;
      background: url(/images/icons/dropdown-arrows@2x.png) white 90% center/10px no-repeat;
    }
  }

  > .spinner {
    background: rgba(215, 111, 32, 0.85);
  }
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 11;
  display: none;

  &::after {
    content: "";
    width: 20px;
    height: 20px;
    background: url(/images/spinner.gif) center/20px no-repeat;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }
}

.map-wrapper {
  position: relative;

  .locations-listing-wrapper {
    position: absolute;
    top: 0;
    left: -33%;
    transition: left 0.3s;
    transition-delay: 1.5s;
    bottom: 0;
    width: 33%;
    z-index: 10;
    background: $white;
    padding: 25px 20px;
    border-bottom: 10px solid $clr11;

    @include media('<=1300px') {
      width: 40%;
      left: -40%;
    }

    @include media('<=tablet-md') {
      width: 100%;
      left: 0;
    }

    @include media('<=tablet') {
      padding: 20px;
    }

    &.slide-in {
      left: 0;
    }

    .btn-wrapper {
      position: absolute;
      bottom: 10px;
      left: 40px;
      right: 40px;
      text-align: center;

      .btn {
        width: 100%;
        max-width: 320px;
        margin-bottom: 15px;

        &.show-all-locations {
          background: $clr17;
        }

        &.new-search {
          color: $white;
          background: $clr11;
          display: none;
          margin-left: auto;
          margin-right: auto;

          .icon {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .locations-listing-wrapper-inner {
    position: relative;
  }

  .locations-listing {
    list-style: none;
    font-size: 14px;
    padding-left: 0;
    overflow: scroll;
    height: 80%;
    top: 20%;
    position: absolute;
    padding-bottom: 100px;

    /* non-standard CSS: https://developer.apple.com/library/content/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html#//apple_ref/doc/uid/TP30001266-SW26 */
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid $clr18;

    @include media('<=tablet-md') {
      left: 15px;
      right: 15px;
      height: 79%;
      top: 20%;
    }

    @include media('<=tablet') {
      left: 20px;
      right: 20px;
    }
  }

  .locations-listing-title {
    font-size: 24px;
    line-height: 30px;
    text-overflow: ellipsis;
    font-family: $font-family-serif;
    font-weight: 400;

    @include media('<=tablet-md') {
      br {
        display: none;
      }
    }

    @include media('<=tablet') {
      font-size: 20px;
    }

    em {
      color: $clr11;
      font-style: normal;
      font-weight: 600;
    }
  }
}

#maplistnoresults {
  padding: 0 25px;

  @include media('<=tablet') {
    padding-left: 0;
    padding-right: 0;
  }

  p {
    color: black;
  }
}

.maplistsearchterm {
  font-style: italic;
}

.no-results {
  .locations-listing {
    top: 31%;
    height: 69%;
    padding-left: 25px;
    padding-right: 25px;
    border-top: 0;

    @include media('<=1300px') {
      top: 36%;
      height: 64%;
    }

    @include media('<=tablet-md') {
      top: 25%;
      height: 75%;
    }

    @include media('<=tablet') {
      padding-left: 0;
      padding-right: 0;
      top: 27%;
      height: 73%;
    }
  }

  .listresult {
    border-top: 0;
    border-bottom: 0;
    padding: 10px 0 28px;

    &::before {
      content: none;
    }
  }

  .btn-wrapper .btn.new-search {
    display: block !important;
  }

  .btn-wrapper .btn,
  .btn-wrapper .btn.new-search {
    @include media('<=tablet-md') {
      display: inline-block !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
  }
}

.error-message em,
.maplistdistance {
  color: $clr11;
  font-style: normal;
  font-weight: 600;
}

.error-message {
  font-family: $font-family-serif;
  font-size: 24px;
  line-height: 30px;
  color: black;

  @include media('<=tablet') {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 5px;
  }
}

.listresult {
  border-top: 1px solid $clr18;
  border-bottom: 1px solid $clr18;
  padding: 28px 28px 28px 80px;
  position: relative;
  font-size: 14px;

  &::before {
    content: "";
    width: 35px;
    height: 35px;
    position: absolute;
    top: 23px;
    left: 30px;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  @include media('<=tablet') {
    padding: 15px 0;

    &::before {
      content: none;
    }
  }

  &:nth-child(2) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_1@2x.png);
    }
  }

  &:nth-child(3) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_2@2x.png);
    }
  }

  &:nth-child(4) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_3@2x.png);
    }
  }

  &:nth-child(5) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_4@2x.png);
    }
  }

  &:nth-child(6) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_5@2x.png);
    }
  }

  &:nth-child(7) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_6@2x.png);
    }
  }

  &:nth-child(8) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_7@2x.png);
    }
  }

  &:nth-child(9) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_8@2x.png);
    }
  }

  &:nth-child(10) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_9@2x.png);
    }
  }

  &:nth-child(11) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_10@2x.png);
    }
  }

  &:nth-child(12) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_11@2x.png);
    }
  }

  &:nth-child(13) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_12@2x.png);
    }
  }

  &:nth-child(14) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_13@2x.png);
    }
  }

  &:nth-child(15) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_14@2x.png);
    }
  }

  &:nth-child(16) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_15@2x.png);
    }
  }

  &:nth-child(17) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_16@2x.png);
    }
  }

  &:nth-child(18) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_17@2x.png);
    }
  }

  &:nth-child(19) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_18@2x.png);
    }
  }

  &:nth-child(20) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_19@2x.png);
    }
  }

  &:nth-child(21) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_20@2x.png);
    }
  }

  &:nth-child(22) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_21@2x.png);
    }
  }

  &:nth-child(23) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_22@2x.png);
    }
  }

  &:nth-child(24) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_23@2x.png);
    }
  }

  &:nth-child(25) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_24@2x.png);
    }
  }

  &:nth-child(26) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_25@2x.png);
    }
  }

  &:nth-child(27) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_26@2x.png);
    }
  }

  &:nth-child(28) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_27@2x.png);
    }
  }

  &:nth-child(29) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_28@2x.png);
    }
  }

  &:nth-child(30) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_29@2x.png);
    }
  }

  &:nth-child(31) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_30@2x.png);
    }
  }

  &:nth-child(32) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_31@2x.png);
    }
  }

  &:nth-child(33) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_32@2x.png);
    }
  }

  &:nth-child(34) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_33@2x.png);
    }
  }

  &:nth-child(35) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_34@2x.png);
    }
  }

  &:nth-child(36) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_35@2x.png);
    }
  }

  &:nth-child(37) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_36@2x.png);
    }
  }

  &:nth-child(38) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_37@2x.png);
    }
  }

  &:nth-child(39) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_38@2x.png);
    }
  }

  &:nth-child(40) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_39@2x.png);
    }
  }

  &:nth-child(41) {
    &::before {
      background-image: url(/images/locations-assets/locsmall/2x/loc_40@2x.png);
    }
  }

  p {
    line-height: 21px;
    margin-bottom: 5px;
  }

  .location-title {
    color: $clr11;
    font-weight: 700;
    display: block;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .location-distance {
    color: $clr2;
    font-weight: 600;
  }

  .location-phone {
    color: $clr2;
  }

  address {
    color: black;
    margin-bottom: 5px;
  }

  .location-more-options {
    padding: 0;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 0;

    li {
      list-style: none;
      float: left;
      display: inline-block;
      margin-right: 20px;

      a {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

#map {
  height: 600px;

  @include media('<=tablet-md') {
    height: 600px;
  }
}

#maplistsummary {
  padding: 0 25px;

  @include media('<=tablet') {
    padding: 0;
  }
}

.infowindow {
  font-size: 16px;
  max-width: 200px;
  font-weight: 400;
  padding: 5px;
}

#maplistnoresults,
.listresult {
  display: none;
}

.custom-popup {
  background: $clr11;
  border-radius: 5px;

  > div,
  > div > div:nth-child(2),
  > div > div:nth-child(4) {
    background: $clr11 !important;
    color: $white;
    font-weight: 600;
  }

  > div:nth-child(1) > div > div > div {
    background: $clr11 !important;
  }

  > div:nth-child(3),
  .gm-style-iw + div {
    background: url(/images/icons/close-white@2x.png) center/100% !important;
    opacity: 1 !important;

    img {
      display: none;
    }
  }
}

.error-message-wrapper {
  background: black;
  border: 3px solid $clr11;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
  display: none;
  font-size: 18px;
  color: $white;
  font-family: $font-family-sans-serif, sans-serif;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: $clr15;
  }
}

#no-locations-match-criteria {
  display: none;
}

.locations-listing-wrapper {
  &.showing-all-locations {
    .btn-wrapper .btn.new-search {
      display: block !important;
    }

    .btn-wrapper .btn.show-all-locations {
      display: none !important;
    }
  }
}

#map img {
  background-color: transparent !important;
}

.moving-box-attached {
  .intro-block .open-close .slide {
    display: none !important;
  }
}
