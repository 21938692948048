@import '../helpers/mixins';
@import '../lib/include-media';

/*
Copyright © 2013 Adobe Systems Incorporated.

Licensed under the Apache License, Version 2.0 (the “License”);
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an “AS IS” BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.accessible-megamenu {
  position: relative;
  display: block;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 3.077em;

  /* color: #454545;
  background-color: #dfe2e2;
  border-color: #b3b3b3;
  border-color: rgba(0, 0, 0, 0.3);
  border-style: solid;
  border-width: 0 1px 1px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; */
}

.accessible-megamenu h2,
.accessible-megamenu h3,
.accessible-megamenu h4 {
  font-size: 1em;
  display: inline;
  margin: 0;
  padding: 0;
  line-height: inherit;
}

.accessible-megamenu .accessible-megamenu-top-nav-item {
  /* position: relative; */
  display: block;
  box-sizing: border-box;
  list-style: none;

  /* margin: 0;
  padding: 0;
  height: 3.077em; */

  float: left;

  /* color: #454545 !important;
   text-shadow: 0 1px 0 #fff;
  -moz-text-shadow: 0 1px 0 #fff;
  border-right: 1px solid #b3b3b3;
  border-right-color: rgba(0, 0, 0, 0.3); */
}

.accessible-megamenu :focus {
  outline: 2px solid rgba(34, 95, 215, 0.3);
  -moz-outline-radius: 3px;
}

.accessible-megamenu .accessible-megamenu-top-nav-item a[aria-expanded] {
  /* position: relative;
  display: block;
  line-height: 3em;
  height: 3em;
  padding-left: 0.769em;
  padding-right: 0.769em;
  color: #454545 !important;
  text-decoration: none;
  box-shadow: inset 1px 1px 0 #fff; */
  z-index: inherit;
}

/*
.accessible-megamenu .accessible-megamenu-top-nav-item a[aria-expanded]:hover,
.accessible-megamenu .accessible-megamenu-top-nav-item a[aria-expanded]:focus {
  color: #333 !important;
  outline-offset: -1px;
}
*/

//.accessible-megamenu .accessible-megamenu-top-nav-item a[aria-expanded]:hover,
.accessible-megamenu .accessible-megamenu-top-nav-item a[aria-expanded]:focus,
.accessible-megamenu .accessible-megamenu-top-nav-item a[aria-expanded].open {
  /* margin-left: 0;
  background: #f5f8fa none;
  background: -webkit-gradient(linear,left top,left bottom,from(#f5f8fa),to(#e3e5e5));
  background: -moz-linear-gradient(top,#f5f8fa,#e3e5e5);
  background: -ms-linear-gradient(top,#f5f8fa,#e3e5e5);
  background: -o-linear-gradient(top,#f5f8fa,#e3e5e5); */
  z-index: 1002;
}

/*
.accessible-megamenu .accessible-megamenu-top-nav-item a[aria-expanded].open {
  background: #f5f8fa none;
  padding-bottom: 2px;
}
*/

/*
.accessible-megamenu .accessible-megamenu-top-nav-item:first-child a[aria-expanded] {
  border-bottom-left-radius: 3px;
}
*/

/*
.accessible-megamenu .accessible-megamenu-top-nav-item:first-child a[aria-expanded].open {
  border-bottom-left-radius: 0px;
  border-left: 1px solid #b3b3b3;
  border-left-color: rgba(0,0,0,0.3);
  left: -1px;
  margin-right: -1px;
}
*/

.accessible-megamenu .accessible-megamenu-panel {
  position: absolute;
  display: block;
  left: -1px;
  color: $menu-text;
  margin: 0;
  padding: 0 0.769em 0.769em;
  line-height: normal;
  background-color: $clr28;

  /* background-image: -webkit-gradient(linear,left top,left bottom,from(#f5f8fa),to(#e3e5e5));
  background-image: -moz-linear-gradient(top,#f5f8fa,#e3e5e5);
  background-image: -ms-linear-gradient(top,#f5f8fa,#e3e5e5);
  background-image: -o-linear-gradient(top,#f5f8fa,#e3e5e5); */
  cursor: default;

  /* -webkit-box-shadow: 0 4px 6px -2px rgba(0,0,0,.5), inset 1px 1px 0 #fff;
     -moz-box-shadow: 0 4px 6px -2px -moz-rgba(0,0,0,.5), inset 1px 1px 0 #fff;
          box-shadow: 0 4px 6px -2px rgba(0,0,0,.5), inset 1px 1px 0 #fff; */

  visibility: hidden;
  top: -9999em;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: opacity 250ms ease 250ms, max-height 500ms ease, visibility 0s linear 500ms, top 0s linear 500ms;
  -moz-transition: opacity 250ms ease 250ms, max-height 500ms ease, visibility 0s linear 500ms, top 0s linear 500ms;
  -ms-transition: opacity 250ms ease 250ms, max-height 500ms ease, visibility 0s linear 500ms, top 0s linear 500ms;
  -o-transition: opacity 250ms ease 250ms, max-height 500ms ease, visibility 0s linear 500ms, top 0s linear 500ms;
  transition: opacity 250ms ease 250ms, max-height 500ms ease, visibility 0s linear 500ms, top 0s linear 500ms;
  border: 1px solid #b3b3b3;
  border-color: rgba(0, 0, 0, 0.3);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.accessible-megamenu .accessible-megamenu-panel.open {
  visibility: visible;
  top: 3em;
  max-height: 600px;
  opacity: 1;
  z-index: 1001;
  -webkit-transition: opacity 250ms ease, max-height 500ms ease, visibility 0s linear 0s, top 0s linear 0s;
  -moz-transition: opacity 250ms ease, max-height 500ms ease, visibility 0s linear 0s, top 0s linear 0s;
  -ms-transition: opacity 250ms ease, max-height 500ms ease, visibility 0s linear 0s, top 0s linear 0s;
  -o-transition: opacity 250ms ease, max-height 500ms ease, visibility 0s linear 0s, top 0s linear 0s;
  transition: opacity 250ms ease, max-height 500ms ease, visibility 0s linear 0s, top 0s linear 0s;
}

.accessible-megamenu .accessible-megamenu-panel a {
  display: inline-block;

  /* font-size: .92em;
  line-height: 1.2em; */

  text-decoration: none;
  color: $menu-text;
  font-weight: bold;

  /* margin-bottom: 0.5em; */
}

.open-close ol li {
  margin: 0 0 16px;
}

.accessible-megamenu .accessible-megamenu-panel ol {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.accessible-megamenu .accessible-megamenu-panel ol > li {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

.accessible-megamenu .accessible-megamenu-panel > ol > li {
  float: left;
  display: block;
  margin-right: 1em;

  /* margin-bottom: 1em; */
  padding-left: 1em;

  /* border-left: 1px solid rgba(0,0,0, 0.3);
  box-shadow: inset 1px 0 0 #fff; */
}

.accessible-megamenu .accessible-megamenu-panel.cols-4 {
  width: 878px;
}

.accessible-megamenu .accessible-megamenu-panel.cols-4 > ol > li {
  width: 22%;
}

.accessible-megamenu .accessible-megamenu-panel.cols-4b {
  width: 878px;
  left: -6.462em;
  border-top-left-radius: 3px;
}

.accessible-megamenu .accessible-megamenu-panel.cols-4b > ol > li {
  width: 22%;
}

.accessible-megamenu .accessible-megamenu-panel.cols-3 {
  width: 724px;
}

.accessible-megamenu .accessible-megamenu-panel.cols-3 > ol > li {
  width: 30%;
}

.accessible-megamenu .accessible-megamenu-panel.cols-1 {
  width: 285px;
}

.accessible-megamenu .accessible-megamenu-panel.cols-1 > ol > li {
  width: auto;
}

/*
.accessible-megamenu .accessible-megamenu-panel > ol > li:first-of-type {
    padding-left: 0;
    border-left: none;
    box-shadow: none;
}
*/

.accessible-megamenu .accessible-megamenu-panel > p {
  color: #454545;
  font-size: 1.2em;
  line-height: 1.1em;
}

.accessible-megamenu .accessible-megamenu-panel > ol > li > h3 > a {
  font-weight: bold;
  color: #454545;
  font-size: 1.2em;
  margin-bottom: 6px;
  padding-top: 0.4em;
  padding-bottom: 0;
  line-height: 1.1em;
}

.accessible-megamenu .accessible-megamenu-panel ol ol > li > a {
  width: auto;
  text-indent: -2.333em;
  padding-left: 2.333em;
}

.accessible-megamenu .accessible-megamenu-panel a:hover,
.accessible-megamenu .accessible-megamenu-panel a:focus {
  color: $menu-text;
  text-decoration: underline;
}

.accessible-megamenu .accessible-megamenu-panel > ol > li > h3 > a:hover,
.accessible-megamenu .accessible-megamenu-panel > ol > li > h3 > a:focus {
  color: #333;
}

.accessible-megamenu .accessible-megamenu-panel hr {
  border-width: 1px 0;
  border-style: solid;
  border-top-color: rgba(0, 0, 0, 0.3);
  border-bottom-color: #fff;
}

/*
.accessible-megamenu-panel li ul {
  margin-top: 5px;
}
*/

/*
.megamenu ul {
  list-style: none;
}
*/

.accessible-megamenu .cols-1 {
  overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/*
// default
.accessible-megamenu-panel ul {
  margin-top: 5px;
  padding-left: 15px;
}

// 2nd level
.accessible-megamenu-panel > ul ul {
  padding-left: 50px;
}

.accessible-megamenu-panel a {
  padding-left: 10px;
}
*/

#menuhead > a {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
