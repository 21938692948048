@import '../helpers/mixins';
@import '../lib/include-media';
@import '../variables/bootstrap-custom';

figure {
  margin: 0;
  padding: 30px 26px;
  text-align: center;
  border: none;
  font-size: $font-size-figure-base;
  line-height: 24px;
  font-weight: 400;

  @include media('>=tablet') {
    float: right;
    width: 31%;
    padding: 0 0 33px 20px;
  }

  @include media('>=tablet-md') {
    font-size: $font-size-h1;
    line-height: 38px;
    padding: 0 0 47px 20px;
  }

  figcaption {
    display: block;
    font-style: italic;
    position: relative;
    color: $clr7;
    font-size: $font-size-figure-caption;
    line-height: 18px;

    @include media('>=tablet-md') {
      font-size: $font-size-h6;
      line-height: 22px;
    }
  }

  img {
    display: block;
    margin: 0 auto 12px;
    max-width: 100%;
    height: auto !important;

    @include media('>=tablet') {
      margin-bottom: 20px;
    }
  }
}

.figure-large {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.figure-left {
  @include media('>=tablet') {
    float: left;
  }

  @include media('>=tablet') {
    padding: 0 20px 0 0;
  }

  @include media('>=tablet-md') {
    padding: 0 20px 0 0;
  }
}

.figure-tall {
  @include media('>=tablet') {
    width: 31%;
  }
}

.figure-wide {
  @include media('>=tablet') {
    width: 45%;
  }
}
