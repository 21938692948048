@import '../helpers/mixins';
@import '../lib/include-media';

/* Library */

@media (min-width: 641px) {
  h1,
  .h1 {
    font-size: 44px;
  }

  h2,
  .h2 {
    font-size: 34px;
  }

  h3,
  .h3 {
    font-size: 30px;
  }

  h4,
  .h4 {
    font-size: 26px;
  }

  .smaller h4 {
    font-size: 20px;
  }

  h5,
  .h5 {
    font-size: 22px;
  }

  h6,
  .h6 {
    font-size: 18px;
  }
}

@media (min-width: 941px) {
  h1,
  .h1 {
    font-size: 46px;
  }

  h2,
  .h2 {
    font-size: 36px;
  }

  h3,
  .h3 {
    font-size: 32px;
  }

  h4,
  .h4 {
    font-size: 28px;
  }

  .smaller h4 {
    font-size: 22px;
  }

  h5,
  .h5 {
    font-size: 24px;
  }

  h6,
  .h6 {
    font-size: 20px;
  }
}

.container-fluid.esc-primary.library {
  background: #d77238 none repeat scroll 0 0;
}

.container.main-nav-subsite.library {
  background: #d77238;
}

form .btn.ebscohost-search-button {
  background: #d76f21 none repeat scroll 0 0;
  color: $white;
  border-radius: 4px !important;
  margin: 0 0 15px;
  min-width: 20%;
  padding: 8px;
  font-size: 12px !important;
  vertical-align: top;
  line-height: 18px;
}

form .btn.ebscohost-search-button:hover {
  color: #e6e6e6;
}

.ebscohost-title {
  margin-top: 15px;
  margin-bottom: 5px;
}

#guidedFieldSelectors .radio {
  display: inline;
}

#guidedFieldSelectors .label {
  color: #333;
}

/* From in-page /library/ */

/* .choose-db-list {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 0;
  font-size: 9pt;
  width: 475px;
}

.choose-db-check {
  width: 20px;
  float: left;
  padding-left: 5px;
  padding-top: 5px;
}

.choose-db-detail {
  margin-left: 30px;
  border-left: solid 1px #E7E7E7;
  padding: 5px 11px 7px 11px;
  line-height: 1.4em;
}

.summary {
  background-color: #1D5DA7;
  color: #FFFFFF;
  border: solid 1px #1D5DA7;
}

.one {
  background-color: #FFFFFF;
  border: solid 1px #E7E7E7;
  border-top: solid 1px #FFFFFF;
}

.two {
  background-color: #F5F5F5;
  border: solid 1px #E7E7E7;
  border-top: solid 1px #FFFFFF;
}

.selected {
  background-color: #E0EFF7;
  border: solid 1px #E7E7E7;
  border-top: solid 1px #FFFFFF
}

#ebscohostCustomSearchBox #disciplineBlock {
  width: auto;
}
#ebscohostCustomSearchBox .limiter {
  float: left;
  margin: 0;
  padding: 0 0 4em;
  width: 50%
}
*/
