@import '../helpers/mixins';
@import '../lib/include-media';
@import '../variables/bootstrap-custom';

.resize-active {
  *,
  *::before,
  *::after {
    transition: none !important;
    animation: none !important;
  }
}

/* ---------------------------------------------------------
   1. General reset
   2. Common site styles
   3. Print style
   --------------------------------------------------------- */

/* ---------------------------------------------------------
   1. General reset
   --------------------------------------------------------- */

// Moved to: base/_base.scss

/* ---------------------------------------------------------
   2. Common  site styles
   --------------------------------------------------------- */

/* main container styles */

/* Hide heading CTA links when on related page */
.disable-nav-to-infosession .nav-to-infosession,
.disable-nav-to-apply .nav-to-apply,
.disable-nav-to-rfi .nav-to-rfi {
  display: none;
}

#wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: 0 auto;
  background: $white;
}

.accessibility {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.accessibility:focus {
  position: static;
  width: auto;
  height: auto;
}

@include skip-to-content();

.hidden {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.link-text,
.button-text {
  visibility: hidden;
  display: block;
  text-indent: -99999px;
  height: 0;
  width: 0;
}

.open-close-wrapper {
  @include media('>=tablet') {
    float: left;
    width: 285px;
    margin: 0 7px 0 0;
  }

  .open-close,
  .megamenu {
    @include media('>=tablet') {
      position: relative;
      top: auto;
      left: 3px;
      right: auto;
    }
  }
}

.admissions .apply-now {
  display: none;
}

@include header-breadcrumb();

/* header styles */

#header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 15;
  transform: translateZ(0);
  background-color: $white;

  .row {
    .header-inner {
      max-width: 1600px;
      margin: 0 auto;
    }

    @include media('>=tablet-md') {
      padding: 51px 15px 0;
    }

    &.top-panel {
      background: $white;
      padding: 15px 0;
      transition: padding 0.25s linear;
      transform: translateZ(0);
      border-bottom: 1px solid $clr4;

      @include media('>=tablet-md') {
        padding: 23px 4px 21px;
        border: none;
        transition-delay: 0.3s;
      }

      @include media('>=tablet-md') {
        padding: 50px 15px 20px;
      }
    }

    &.breadcrumb-holder {
      @include media('>=tablet') {
        background: $clr20;
        padding: 0 15px 0;
      }
    }
  }

  @include media('<=tablet-md') {
    background-color: $primary-blue;
    border-top: 0;
    transform: translateZ(0);
    backface-visibility: hidden;

    .top-panel {
      height: 75px;
      margin-top: 40px;
    }

    * {
      backface-visibility: hidden;
    }
  }

  &.hide-bar {
    border-width: 0;
  }

  &.fixed-position {
    left: 0 !important;
    width: 100%;
    min-width: 320px;
  }

  .holder {
    margin: 0 -15px;
    padding: 0 30px;

    @extend %clearfix;
  }

  .search-panel {
    position: static;

    @include media('>=tablet') {
      position: relative;
    }
  }
}

.no-alt-header .breadcrumb-holder {
  .open-close-wrapper {
    display: none;
  }
}

#main > .open-close,
#header > .open-close {
  @include media('>=tablet') {
    display: none;
  }
}

body.moving-box-attached.no-alt-header {
  .breadcrumb-holder {
    .open-close-wrapper {
      display: block;
    }
  }

  .intro-block {
    .open-close-wrapper {
      display: none;
    }
  }
}

/* page logo styles */

.logo {
  position: absolute;
  top: 0;
  left: 29px;
  width: 175px;
  height: 126px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: $primary-orange;
  z-index: 250;

  @include media('>=tablet-md') {
    width: 203px;
    height: 146px;
  }

  img {
    @include img-styles();

    width: 167px;
    max-width: 100%;
    height: auto;
    top: 25px;
    left: 18px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
    padding: 10px 0 0 5px;

    @include media('>=tablet-md') {
      transition: width 0.25s linear;
      transform: translateZ(0);
      transition-delay: 0.3s;
      max-width: 100%;
      vertical-align: top;
      position: relative;
      top: 25px;
      left: 18px;
      width: 167px;
      height: auto;
      padding: 0;
    }
  }
}

/*
.logo-footer {
  float: left;

  a {
    @include vertical();

    width: 168px;

    @include media('>=tablet') {
      width: 202px;
    }

    @include media('>=tablet-md') {
      width: 275px;
      transition: width 0.25s linear;
      transform: translateZ(0);
      transition-delay: 0.3s;
    }

    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1) translate3d(0, 0, 0);

    img {
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
    }
  }

  img {
    @include img-styles();

    width: 275px;
  }
}
*/

@include header-info-links();
@include header-cta-header();

.quick-links .search-opener .icon-search {
  @include vertical();

  line-height: 1;
  font-size: 19px;
  transition: opacity 0.25s linear;

  @include media('>=tablet') {
    padding: 0 0 0 8px;
  }
}

.search-active {
  @include media('>=tablet') {
    .quick-links li {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.25s linear, visiblity 0.25s linear;
    }
  }

  .quick-links .search-opener .icon-search {
    @include media('<tablet') {
      opacity: 0;
      visibility: hidden;
    }
  }

  .quick-links .search-opener .icon-cross {
    @include media('<tablet') {
      opacity: 1;
      visibility: visible;
    }
  }
}

.nav-opener {
  display: block;
  top: 13px;
  right: 12px;

  @include size(30px);
  @include hide-text();
  @include pseudo-element(before, 50%, 20%, auto, 20%);
  @include pseudo-element(after, 50%, 20%, auto, 20%);

  position: absolute;

  @include media('>=tablet') {
    top: 2px;
    right: 8px;
  }

  &::before,
  &::after {
    background: $clr24;
    height: 2px;
    transition: all 0.35s ease-out;
  }

  &::before {
    margin: -6px 0 0;
  }

  &::after {
    margin: 4px 0 0;
  }

  span {
    position: absolute;
    transition: all 0.35s ease-out;
    left: 20%;
    right: 20%;
    background: $clr24;
    height: 2px;
    display: block;
    top: 50%;
    margin: -1px 0 0;
  }

  .nav-active & {
    &::before,
    &::after {
      transform: rotate(45deg);
      margin: 0;
    }

    &::after {
      transform: rotate(-45deg);
    }

    span {
      opacity: 0;
    }
  }
}

.nav-drop {
  @extend %clearfix;

  @include media('<tablet-md') {
    transition: all 0.35s ease-out;
    transform: translateY(-100%) translateZ(0);
    padding: 15px 0 102px 48px;
    position: relative;
  }

  @include media('<tablet') {
    padding: 15px 0 110px 20px;
  }

  .nav-active & {
    @include media('<tablet-md') {
      transform: translateY(0) translateZ(0);
    }
  }
}

.nav-holder {
  margin-bottom: 14px;

  @include media('>=widescreen-2') {
    margin-bottom: 22px;
  }

  @include media('<tablet-md') {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 0;
    transition: all 0.35s ease-out;
    background: rgba($gray-base, 0.95);
    z-index: 2;
    -webkit-overflow-scrolling: touch;
  }

  .nav-active & {
    @include media('<tablet-md') {
      height: calc(100vh);
      padding-bottom: 84px;
    }

    @include media('<tablet') {
      height: calc(100vh);
      padding-bottom: 64px;
    }
  }

  .col-xs-12 {
    position: static;
  }
}

/* main navigation styles */

#nav {
  position: relative;
  z-index: 20;
  // padding-left: 240px;

  .nav-active & {
    > ul {
      > li {
        opacity: 1;
        transform: none;
        transition-delay: 300ms, 300ms;

        &:nth-child(2) {
          transition-delay: 350ms, 350ms;
        }

        &:nth-child(3) {
          transition-delay: 400ms, 400ms;
        }

        &:nth-child(4) {
          transition-delay: 450ms, 450ms;
        }

        &:nth-child(5) {
          transition-delay: 500ms, 500ms;
        }

        &.ac-active {
          .ac-slide {
            ul {
              li {
                opacity: 1;
                transform: none;
                transition-delay: 300ms, 300ms;

                &:nth-child(2) {
                  transition-delay: 350ms, 350ms;
                }

                &:nth-child(3) {
                  transition-delay: 400ms, 400ms;
                }

                &:nth-child(4) {
                  transition-delay: 450ms, 450ms;
                }

                &:nth-child(5) {
                  transition-delay: 500ms, 500ms;
                }

                &:nth-child(6) {
                  transition-delay: 500ms, 500ms;
                }
              }
            }
          }
        }
      }
    }
  }

  > ul {
    // @extend %listreset;
    margin: 0;
    padding: 0;
    list-style: none;

    @include font(22, 26, 700);

    text-transform: capitalize;
    position: relative;
    z-index: 2;

    @include media('>=tablet-md') {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: center;

      @include fontsize(15, 18);

      padding: 0 0 0 15px;
      transition: font-size 0.3s;
      transition-delay: 0.3s;
    }

    > li {
      position: relative;
      padding: 0 41px 0 0;
      border-bottom: 1px solid rgba($white, 0.3);
      transform: translateY(-30px) translateZ(0);
      opacity: 0;
      transition: opacity 0.35s ease-out, transform 0.35s ease-out;

      @include media('>=tablet') {
        padding: 0 80px 0 4px;
      }

      @include media('>=tablet-md') {
        width: 13%;
        padding: 0;
        position: static;
        display: flex;
        flex-flow: row wrap;
        border-bottom: none;
        transition: none;
        transform: none;
        opacity: 1;
        margin-left: -1px;

        &.special {
          border: 4px solid #00438c;
          border-radius: 6px;
        }
      }

      &.hover,
      &.active,
      .open {
        > a {
          @include media('>=tablet-md') {
            background: $clr19;
            color: $white;

            /* margin: 0 0 -1px;
            padding-bottom: 17px; */
            position: relative;

            &::after {
              font-family: "FontAwesome", sans-serif;
              content: "\f0d7";
              padding-left: 10px;

              /*
              width: 100%;
              display: block;
              height: 1px;
              background: $clr33;
              position: absolute;
              bottom: -1px;
              left: 0;
              */
            }
          }
        }
      }

      > a:focus {
        @include media('>=tablet-md') {
          background: $clr19;
          color: $white;

          /* margin: 0 0 -1px;
          padding-bottom: 17px; */
          position: relative;

          &::after {
            font-family: "FontAwesome", sans-serif;
            content: "\f0d7";
            padding-left: 10px;

            /*
            width: 100%;
            display: block;
            height: 1px;
            background: $clr33;
            position: absolute;
            bottom: -1px;
            left: 0;
            */
          }
        }
      }

      &.ac-active {
        .ac-opener {
          &::after {
            transform: translateY(-50%) translateZ(0) rotate(180deg);
            opacity: 0;
          }
        }
      }

      &.hover,
      &:focus,
      .open {
        @include media('>=tablet-md') {
          .ac-slide {
            opacity: 1;
            visibility: visible;
            transition-delay: 300ms;

            .slide-holder {
              /* transform: translateY(0); */
            }
          }

          .clearfix-wrap,
          .title {
            opacity: 1;
            transition-delay: 0ms;
          }
        }
      }

      > a {
        display: block;
        font-weight: 400;
        padding: 16px 0;
        transition: background 0.2s linear, color 0.2s linear;
        width: 100%;
        color: $white;

        @include media('>=tablet') {
          padding: 17px 0;
        }

        @include media('>=tablet-md') {
          padding: 16px 3px;
          font-weight: 700;
          text-align: center;
          // background: $clr5;
          align-items: center;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          // border: solid $clr18;
          // border-width: 0 1px;
          color: $clr3;
          transition: background 0s linear, color 0s linear, padding 0.3s linear 0.3s;
        }

        &.ac-opener {
          @include btn-plus(25px, 2px, rgba($white, 0.7), 20px, 12px);

          text-indent: -9999px;
          padding: 0;

          @include media('>=tablet') {
            right: 44px;
          }

          @include media('>=tablet-md') {
            display: none;
          }

          &::before,
          &::after {
            left: 25%;
            right: 25%;
          }

          &:hover,
          &:focus {
            opacity: 0.7;
          }
        }

        &:hover,
        &:focus {
          @include media('<tablet-md') {
            color: $clr15;
          }
        }
      }
    }
  }
}

.ac-slide {
  @include font(14, 20, 400);

  padding: 0 0 16px;

  @include media('>=tablet-md') {
    position: absolute !important;
    top: 100% !important;
    left: 33px !important;
    right: 33px !important;
    width: auto !important;
    height: auto !important;
    z-index: 1;
    opacity: 0;
    overflow: hidden !important;
    visibility: hidden;
    padding: 0 30px;
    margin: 0 -30px 0;

    @include fontsize(16, 20);

    transition: all 0.3s;
  }

  .slide-holder {
    @include media('>=desktop') {
      padding: 43px 94px 38px 109px;
      min-height: 381px;
    }

    @include media('>=tablet-md') {
      @include bg-color(before, rgba($clr33, 0.95));

      padding: 30px 40px;
    }

    &::before {
      @include media('>=tablet-md') {
        border-top: 1px solid rgba($clr33, 0.95);
      }
    }
  }

  .clearfix-wrap {
    @extend %clearfix;

    @include media('>=tablet-md') {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 -10px;
      transition: opacity 0.35s ease-out 0.2s;
      opacity: 0;
    }
  }

  ul {
    @extend %listreset;

    @include media('>=tablet-md') {
      @include fontsize(20, 26);
    }

    li {
      margin: 0 0 16px;

      @include media('>=tablet-md') {
        margin: 0 0 14px;
      }

      @include media('<tablet-md') {
        transform: translateY(-30px) translateZ(0);
        opacity: 0;
        transition: opacity 0.35s ease-out, transform 0.35s ease-out;
      }

      &.active {
        a {
          color: $clr15;
        }
      }
    }

    a {
      font-weight: 400;
      color: $white;
      transition: color 0.25s linear;

      &:hover,
      &:focus {
        color: $clr15;
      }
    }
  }

  .col {
    @include media('>=tablet-md') {
      width: 36.3%;
      padding: 0 10px;
    }

    &:nth-child(1) {
      @include media('>=tablet-md') {
        width: 33.7%;
      }
    }

    &:nth-child(2) {
      @include media('>=tablet-md') {
        width: 30%;
      }
    }
  }

  .title {
    display: none;
    color: $white;

    @include font(36, 38);

    margin: 0 0 21px;
    transition: all 0.3s;
    opacity: 0;

    &:hover,
    &:focus {
      color: $clr15;
    }

    @include media('>=tablet-md') {
      display: block;
    }
  }

  .featured-col,
  .aid-list {
    padding: 9px 0 0;

    @include media('>=tablet-md') {
      margin-top: 2px;
      padding: 0;
    }

    &.two-links {
      @include media('>=tablet') {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
      }

      @include media('>=tablet-md') {
        display: block;
      }

      a {
        display: block;
        padding: 15px 15px 18px 58px;
        margin: 0 0 8px;

        @include media('>=tablet') {
          padding: 15px 58px 18px;
          margin: 0 1% 0 0;
          width: 48.8%;
        }

        @include media('>=tablet-md') {
          padding: 17px 10px 12px;
          margin: 0 0 10px;
          width: auto;
        }
      }

      .icon {
        top: 15px;

        @include media('>=tablet-md') {
          left: auto;
          top: auto;
          margin: 0 0 12px;
        }
      }

      .link-title {
        margin: 0;

        @include media('>=tablet-md') {
          margin: 0;
        }
      }

      .description {
        display: none;
      }
    }

    a {
      border: 2px solid rgba($clr21, 0.6);
      border-radius: 5px;
      color: $white;
      transition: background 0.25s linear, color 0.25s linear, border 0.35s ease-out;
      font-weight: 400;
      padding: 14px 14px 14px 59px;
      display: none;
      position: relative;

      @include media('>=tablet') {
        padding: 11px 58px 15px;
      }

      @include media('>=tablet-md') {
        text-align: center;
        padding: 30px 25px 22px;
      }

      &:hover,
      &:focus {
        border-color: $clr15;
        color: $clr15;
        background: $clr33;
      }

      &:first-child {
        display: block;
      }
    }

    .icon {
      position: absolute;
      left: 18px;
      top: 11px;
      font-size: 22px;

      @include media('>=tablet-md') {
        display: block;
        position: relative;
        top: auto;
        left: auto;
        font-size: 34px;
        margin: 0 0 22px;
      }
    }

    .link-title {
      display: block;

      @include fontsize(16, 20);

      margin: 0 0 7px;

      @include media('>=tablet-md') {
        margin: 0 0 19px;

        @include fontsize(20, 24);
      }
    }

    .description {
      text-transform: none;
    }
  }

  .aid-list {
    padding: 0 8px 0 0;

    @include media('>=tablet') {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0 -5px;
    }

    @include media('>=tablet-md') {
      margin: 0 -30px;
      padding: 6px 0 0;
    }

    li {
      margin: 0 0 8px;

      @include media('>=tablet') {
        width: 25%;
        margin: 0;
        padding: 0 5px;
      }

      @include media('>=tablet-md') {
        width: 24.6%;
        padding: 0 15px 15px;
      }

      &.active {
        a {
          border-color: $clr15;
          color: $clr15;
          background: $clr33;
        }
      }
    }

    a {
      display: block;
      position: relative;
      padding: 15px 14px 18px 59px;

      @include media('>=tablet') {
        @include size(100%);

        text-align: center;
        padding: 18px 10px 25px;
      }

      @include media('>=tablet-md') {
        padding: 18px 10px 22px;
      }
    }

    .icon {
      display: block;
      font-size: 24px;
      left: 18px;
      top: 50%;
      transform: translateY(-50%) translateZ(0);

      @include media('>=tablet') {
        position: relative;
        font-size: 35px;
        margin: 0 0 12px;
        left: auto;
        right: auto;
        top: auto;
        transform: none;
        min-height: 50px;
      }

      @include media('>=tablet-md') {
        font-size: 52px;
        margin: 0 0 9px;
        min-height: 65px;
      }

      &::before {
        @include media('>=tablet') {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) translateZ(0);
        }
      }

      &.icon-apply {
        @include media('>=tablet') {
          font-size: 45px;
        }

        @include media('>=tablet-md') {
          font-size: 65px;
        }

        @include media('<tablet') {
          font-size: 25px;
          left: 19px;
        }
      }

      &.icon-hat {
        @include media('<tablet') {
          font-size: 20px;
          left: 17px;
        }
      }

      &.icon-financial-aid {
        @include media('<tablet') {
          font-size: 17px;
          left: 16px;
        }
      }
    }

    .link-title {
      text-transform: none;
      margin: 0;

      @include media('>=tablet-md') {
        margin: 0;
      }
    }
  }
}

.add-nav,
.add-nav2 {
  @include font(14, 18, 600);

  padding: 34px 0 0;

  .nav-active & {
    > ul {
      > li {
        @include media('<tablet-md') {
          opacity: 1;
          transform: none;
          transition-delay: 650ms, 650ms;
        }

        &:nth-child(2) {
          @include media('<tablet-md') {
            transition-delay: 700ms, 700ms;
          }
        }

        &:nth-child(3) {
          @include media('<tablet-md') {
            transition-delay: 750ms, 750ms;
          }
        }

        &:nth-child(4) {
          @include media('<tablet-md') {
            transition-delay: 800ms, 800ms;
          }
        }
      }
    }
  }

  > ul {
    margin: 0;

    > li {
      padding: 0;
      display: block;
      margin: 0 0 14px -2px;

      @include media('>=tablet-md') {
        @include vertical(initial);

        margin: 0 0 0 10px;
      }

      @include media('<tablet-md') {
        transform: translateY(-30px) translateZ(0);
        opacity: 0;
        transition: opacity 0.35s ease-out, transform 0.35s ease-out;
      }

      &:hover {
        > a {
          position: relative;

          &::before {
            content: "";
            display: block;
            height: 15px;
            width: 100%;
            position: absolute;
            background: transparent;
            top: 100%;
          }
        }

        ul {
          @include media('>=tablet-md') {
            display: block;
          }
        }
      }
    }

    ul {
      @include media('>=tablet-md') {
        background: $clr2;
        color: $white;
        padding: 12px 20px;
        list-style: none;
        position: absolute;
        right: 0;
        top: 25px;
        z-index: 20;
        width: 230px;
        display: none;

        a {
          color: $white;
          display: block;
          font-weight: 400;
          padding: 6px 0;

          &:hover {
            color: $clr15;
          }
        }
      }

      @include media('<=tablet-md') {
        padding: 12px 0;
        list-style: none;
        position: relative;
        top: 0;
        z-index: 20;
        width: 100%;
        display: none;

        a {
          color: $header-text;
          display: block;
          font-weight: 400;
          padding: 6px 0;

          &:hover {
            color: $clr15;
          }
        }
      }
    }
  }

  a {
    color: $header-text;

    @include media('<=tablet-md') {
      &.on {
        .caret {
          transform: rotate(180deg);
        }
      }
    }

    &.tel-link {
      font-weight: 400;

      @include media('>=tablet-md') {
        margin: 0 9px 0 0;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.add-nav2 {
  ul {
    @include media('<tablet-md') {
      padding: 12px 0;
      list-style: none;
      position: relative;
      top: 0;
      z-index: 20;
      width: 100%;
      display: none;
    }
  }

  a {
    color: $white;
    transition: color 0.25s linear;

    @include media('<tablet-md') {
      color: white;
      display: block;
      font-weight: 400;
      padding: 6px 0;

      &:hover {
        color: $clr15;
      }
    }
  }

  & > ul > li {
    &:hover > a {
      position: relative;
    }

    &:hover > a::before {
      content: "";
      display: block;
      height: 15px;
      width: 100%;
      position: absolute;
      background: transparent;
      top: 100%;
    }

    @include media('<tablet-md') {
      transform: translateY(-30px) translateZ(0);
      opacity: 0;
      transition: opacity 0.35s ease-out, transform 0.35s ease-out;
    }
  }
}

.top-bar {
  @include media('>=tablet-md') {
    position: absolute;
    top: 0;
    width: 100%;
    background: $primary-blue;
    z-index: 190;
    height: 36px;
    margin-left: -15px;

    .search-form .search-close {
      color: $header-text;

      &:hover {
        text-decoration: underline;
      }
    }

    .add-nav {
      position: absolute;
      top: 0;
      padding: 4px 5px 4px;
      width: 100%;
      background: $primary-blue;
      color: $header-text;
      z-index: 200;
      transition: opacity 0.25s linear, visibility 0.25s linear;
      transition-delay: 0.3s;

      a {
        font-weight: 400;
      }

      .request-info a,
      .attend-info a {
        color: $black;
        background-color: $primary-orange;
        padding: 3px 12px;
        text-transform: none;
      }

      .apply-now a {
        // background: $secondary-blue 0 0 no-repeat padding-box;
        // border-radius: 5px;
        padding: 3px 12px;
        background-color: $secondary-blue;
        text-transform: none;
        min-width: 100px;

        &:hover {
          background-color: $white;
          color: $secondary-blue;
        }
      }
    }
  }
}

.btn-close {
  position: absolute;
  bottom: 35px;
  left: 50%;
  display: block;
  color: $white;
  text-transform: capitalize;
  background: rgba($clr26, 0.8);
  transition: background 0.25s linear, opacity 0.35s ease-out, transform 0.35s ease-out;
  text-align: center;
  transform: translate(-50%, -30px) translateZ(0);
  opacity: 0;
  width: 235px;
  padding: 12px 5px;

  @include fontsize(14, 20);

  .nav-active & {
    opacity: 1;
    transform: translate(-50%, 0) translateZ(0);
    transition-delay: 850ms, 850ms;
  }

  &:hover,
  &:active,
  &:focus {
    color: $white;
    background: rgba($clr26, 0.4);
  }

  .cross {
    @include hide-text();

    display: block;

    @include btn-close(18px, 3px, $white, auto, auto);
    @include vertical();

    position: relative;
    margin: -1px 10px 0 0;
  }
}

header#header.container-fluid {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

*:focus {
  outline: 1px dotted $clr30 !important;
}

/* Note: @supports is a W3C Candidate Recommendation */

/* @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
  .add-nav,
  .logo a,
  .logo a img,
  .logo,
  .search-panel,
  .seach-form,
  #header .row.top-panel,
  .cta-header a,
  .cta-header,
  .cta-header li,
  #nav > ul > li > a,
  #nav > ul,
  .info-links,
  .add-nav a,
  #header,
  #nav,
  #nav .nav-drop,
  #header .top-panel *,
  #header .breadcrumb-holder {
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
} */
