@import '../variables/bootstrap-custom';
@import '../helpers/mixins';
@import '../lib/include-media';

body.pl-typical-combined {
  #wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "header" "sidebar" "content" "footer";
    width: 100%;

    @include media('>=tablet-md') {
      grid-gap: 10px;
      grid-template-columns: minmax(250px, 1fr) minmax(0, 1fr) minmax(0, 1fr) 0.5fr;
      grid-template-areas: "header  header  header header" "sidebar content content ." "footer  footer  footer footer";
    }

    & > * {
      min-width: 0;
    }

    #main {
      padding-top: unset;
    }

    .stand-alone-menu {
      grid-area: sidebar;
      position: relative;
    }

    main {
      grid-area: content;
      padding: 1rem;
    }

    .message-banner,
    .nav-breadcrumb {
      grid-area: header;
    }

    footer {
      grid-area: footer;
    }

    .footer-cta {
      grid-area: footer;
    }

    .bottom-section {
      grid-area: footer;
    }
  }

  .nav-breadcrumb .header-inner .breadcrumb {
    border-bottom: $clr9 1px solid;
  }

  .nav-breadcrumb {
    margin: 25px 5% 0;
    background: $white;
    border: none;
  }

  .breadcrumb {
    font-size: 0.8em;
    color: $link-color;

    & > li {
      font-weight: initial;
      padding: 0 12px;

      &::before {
        content: "\0203A\00A0";
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        transform: translateY(-50%) translateZ(0);
        height: 3px;
        width: 3px;
        border: none;
        font-size: 24px;
        font-weight: lighter;
      }

      &:last-child {
        font-weight: bold;
      }
    }

    a {
      color: $link-color;
    }
  }

  .banner {
    z-index: 0;

    h1 {
      line-height: 36px;
    }

    &.intro-block {
      margin-bottom: 0;

      .holder {
        padding: 20px 0 10px;
      }

      h1 {
        margin: 0;
      }
    }
  }

  .footer-text-button-cta {
    padding: 10px 25px;
    background-color: $clr35;

    .heading {
      font-family: $font-family-sans-serif, sans-serif;
      font-size: 2.2rem;
      font-weight: 600;
    }

    .btn {
      margin: 10px 0;
      max-width: 100%;
    }
  }

  .footer-simple-cta {
    .btn {
      margin: 10px 25px;
    }
  }

  hr {
    height: 1px;
    background-color: #ccc;
  }

  .v-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
