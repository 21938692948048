@import 'helpers';
@import '../lib/include-media';
@import '../variables/bootstrap-custom';

// vertical align el inside parent with fixed height/min-height
// usage
// html - .parent>.child
// scss - @include v-align;
//     or @include v-align(250px);
//     or @include v-align(250px, bottom, before);
//
@mixin v-align($va-height: 100%, $va-direction: middle, $va-pseudo: after) {
  white-space: nowrap;
  text-align: center;

  &:#{$va-pseudo} {
    content: '';
    display: inline-block;
    vertical-align: $va-direction;
    width: 0;
    min-height: $va-height;
  }

  .align {
    white-space: normal;
    display: inline-block;
    vertical-align: $va-direction;
    max-width: 99%;
  }
}

@mixin v-align-img($va-height: 100%, $va-direction: middle, $va-pseudo: after) {
  white-space: nowrap;
  text-align: center;

  &:#{$va-pseudo} {
    content: '';
    display: inline-block;
    vertical-align: $va-direction;
    width: 0;
    min-height: $va-height;
  }

  img {
    white-space: normal;
    display: inline-block;
    vertical-align: $va-direction;
    max-width: 99%;
  }
}

// vertical align a pair of child el inside parent
// usage
// html - .post>.image+.text
// scss - @include v-align-pair(image, text);
//     or @include v-align-pair(image, text, bottom);
//     or @include v-align-pair(image, text, middle, 200px);
//

@mixin v-align-pair($child-name1, $child-name2, $valign: middle, $width1: auto, $width2: auto ) {
  display: table;

  .#{$child-name1} {
    display: table-cell;
    vertical-align: $valign;
    width: $width1;
  }

  .#{$child-name2} {
    display: table-cell;
    vertical-align: $valign;
    width: $width2;
  }
}

// vertical align el
// usage: @include vertical-align-el;
//
@mixin vertical-align-el {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/// Mixin helping defining both `width` and `height` simultaneously.
///
/// @author Hugo Giraudel
///
/// @access public
///
/// @param {Length} $width - Element’s `width`
/// @param {Length} $height ($width) - Element’s `height`
///
/// @example scss - Usage
///   .foo {
///     @include size(10em);
///   }
///
///   .bar {
///     @include size(100%, 10em);
///   }
///
/// @example css - CSS output
///   .foo {
///     width: 10em;
///     height: 10em;
///   }
///
///   .bar {
///     width: 100%;
///     height: 10em;
///   }
///
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/// font-smoothing
/// @include font-smoothing(on);
/// @include font-smoothing(off);
@mixin font-smoothing($value:on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

/// Creates a visual triangle.
/// Mixin takes ($size, $color, $direction)
/// The $size argument can take one or two values—width height.
/// The $color argument can take one or two
/// values—foreground-color background-color.
///
/// @author http://bourbon.io/docs/#triangle
///
/// $direction:
/// up, down, left, right, up-right, up-left, down-right, down-left
/// @example scss - Usage
///   @include triangle(12px, gray, down);
///   @include triangle(12px 6px, gray blue, up-left);
///
@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);

  height: 0;
  width: 0;

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    }

    @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }

    @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    }

    @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  }

  @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left: $width solid $background-color;
    }

    @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left: $width solid $background-color;
    }

    @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  }

  @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  }

  @else if ($direction == inset-right) {
    border-color: $background-color $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $width $height;
  }

  @else if ($direction == inset-left) {
    border-color: $background-color $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}

/// Animate css properties
// usage
// scss - @include animate(color);
//     or @include animate(color width);
//     or @include animate(color width, 1s, linear);
//
$animation-speed: 0.3s !default;

@mixin animate($properties, $duration: $animation-speed, $easing: linear) {
  $list: ();

  @each $prop in $properties {
    $str: #{$prop} #{$animation-speed} #{$easing};
    $list: join($list, $str, comma);
  }

  transition: $list;
}

// btn-close
@mixin btn-close($cross-size, $line-width, $cross-color, $position-top, $position-right) {
  position: absolute;
  width: $cross-size;
  height: $cross-size;
  top: $position-top;
  right: $position-right;
  transition: all 0.25s linear;

  @include hide-text();

  display: block;

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    left: 0;
    right: 0;
    height: $line-width;
    background: $cross-color;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 0.75;
  }
}

// btn-plus
@mixin btn-plus($plus-size, $line-width, $plus-color, $position-top, $position-right) {
  position: absolute;
  width: $plus-size;
  height: $plus-size;
  top: $position-top;
  right: $position-right;

  @extend %transition;

  display: block;

  @include hide-text();

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    left: 5%;
    right: 5%;
    height: $line-width;
    background: $plus-color;
    transform: translateY(-50%);

    @extend %transition;
  }

  &::after {
    transform: translateY(-50%) rotate(90deg);
  }

  &:hover {
    &::before,
    &::after {
      background: darken($plus-color, 10%);
    }
  }
}

// custom font mixin

@mixin fontface($fontfamily, $filename, $weight: normal, $style: normal) {
  font-family: $fontfamily;
  src: url('../fonts/#{$filename}.eot');
  src:
    url('../fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
    // url('../fonts/#{$filename}.woff2') format('woff2'),
    url('../fonts/#{$filename}.woff') format('woff'),
    url('../fonts/#{$filename}.ttf') format('truetype');
  // url('../fonts/#{$filename}.svg#{$filename}') format('svg');
  font-weight: $weight;
  font-style: $style;
}

//Font
@mixin font($size: 16, $lineheight: 16, $weight:400) {
  font-size: $size + px;
  line-height: $lineheight + px;
  font-weight: $weight;
}

//Font-size
@mixin fontsize($size: 16, $lineheight: 16) {
  font-size: $size + px;
  line-height: $lineheight + px;
}

//alignment
@mixin align-top() {
  display: inline-block;
  vertical-align: top;
}

//align-center
@mixin align-center() {
  max-width: $base-max-width;
  margin: 0 auto;

  @extend %clearfix;

  padding: 0 15px;
}

// link color
@mixin link-color($color) {
  color: $color;

  &:hover,
  &:focus {
    opacity: 0.8;
    text-decoration: none;

    &::after,
    &::before {
      opacity: 0.8;
    }
  }
}

// vertical
@mixin vertical($vertical: top) {
  display: inline-block;
  vertical-align: $vertical;
}

// pseudo-element (after and/or before)
@mixin pseudo-element($element, $top, $right, $bottom, $left) {
  position: relative;

  &::#{$element} {
    position: absolute;
    display: block;
    content: "";
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
  }
}

@mixin v-align-min($child-class-name, $valign: middle) {
  white-space: nowrap;

  .#{$child-class-name} {
    display: inline-block;
    white-space: normal;
    vertical-align: $valign;
  }

  &::after {
    content: '';
    vertical-align: $valign;
    display: inline-block;
    min-height: inherit;
    width: 1px;
    overflow: hidden;
    margin: 0 0 0 -5px;
  }
}

// responsive image styles
@mixin img-styles() {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

// extent background
@mixin bg-color($element, $bg-color) {
  position: relative;

  &:#{$element} {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    bottom: 0;
    left: -9999px;
    right: -9999px;
    background: $bg-color;
    z-index: -1;
  }
}

// Position Absolute
@mixin position($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

// Color Transition
@mixin color($color) {
  color: $color;
  transition: color 0.25s linear;

  &:hover {
    color: darken($color, 10%);
  }
}

/// Mixin for styling form-placeholders
/// @example scss - Usage
///   @include placeholder {
///    color: #333;
///   }
///
///   input[type='text'],
///   input[type='tel'],
///   input[type='email'] {
///     @include placeholder {
///      color: red;
///     }
///   }

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    opacity: 1;

    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &.placeholder {
    @content;
  }
}

/// Font-size px to rem calc
/// @example scss - Usage
/// selector {
///    @include rem-font-size(16px);
/// }

@mixin rem-font-size($pxSize) {
  font-size: ($pxSize / 10px) + rem;
}

@mixin btn-list-tablet() {
  @include media('>=tablet') {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
    transform: translateY(15px);
    transition: opacity 0.6s ease-out 0.4s, transform 0.6s ease-out 0.4s;
  }
}

@mixin common-slide-video() {
  .icon-stop {
    position: absolute;
    top: -15px;
    right: -15px;
    left: auto;
    margin: 0;
    width: 30px;
    height: 30px;
    display: block;
    background: $clr24;
    border-radius: 50%;
    transform: none;
    opacity: 0;
    z-index: 10;

    @include media('<=tablet-md') {
      right: 12px;
      top: 12px;
    }

    &::before {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      bottom: auto;
      left: 20%;
      right: 20%;
      background: $white;
      height: 2px;
      -webkit-transition: all 0.35s ease-out;
      transition: all 0.35s ease-out;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -1px;
    }

    &::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      bottom: auto;
      left: 20%;
      right: 20%;
      background: $white;
      height: 2px;
      -webkit-transition: all 0.35s ease-out;
      transition: all 0.35s ease-out;
      margin-top: -1px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .bg-stretch {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;

    img {
      width: 100%;
      height: auto;
    }
  }

  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

@mixin skip-to-content() {
  #skiptocontent,
  #skip-to-content {
    a {
      padding: 6px;
      position: absolute;
      top: -1000px;
      left: -1000px;
      color: white;
      border-right: 1px solid white;
      border-bottom: 1px solid white;
      border-bottom-right-radius: 8px;
      background: transparent;
      transition: top 1s ease-out, background 1s linear;
      z-index: 1000;
    }

    a:focus {
      position: absolute;
      left: 0;
      top: 0;
      background: $brand-danger;
      outline: 0;
      transition: top 0.1s ease-in, background 0.5s linear;
    }
  }
}

@mixin header-breadcrumb() {
  .breadcrumb {
    margin: 0;

    @include font(12, 16, 400);

    color: $gray-base;
    display: none;
    overflow: hidden;

    @include media('>=tablet') {
      padding: 17px 32px;
      display: block;

      /* transition: opacity 0.5s ease-out 0.3s, transform 0.5s ease-out 0.4s;
      transform: translateY(-40px) translateZ(0);
      opacity: 0; */
    }

    @include media('>=tablet-md') {
      padding: 17px 8px;
    }

    @include media('<=tablet-md') {
      opacity: 0 !important;
      display: none;
      //    pointer-events: none; // Valid Only for SVG
      cursor: default;
    }

    &.in-viewport {
      opacity: 1;
      transform: none;
    }

    > li {
      padding: 0 6px;
      position: relative;
      font-weight: 700;

      @include vertical();

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: -3px;
        padding: 0;
        transform: translateY(-50%) translateZ(0);

        @include triangle(6px, $gray-base, right);
      }

      + li {
        &::before {
          content: '';
          padding: 0;
        }

        &:last-child::before {
          content: none;
        }
      }
    }

    a {
      color: $gray-base;
      font-weight: 400;
      transition: color 0.25s linear;

      &:hover {
        color: $gray-base;
        text-decoration: underline;
      }
    }
  }

  #header.hide-breadcrumb {
    .breadcrumb {
      @include media('<=tablet-md') {
        opacity: 0 !important;
      }
    }
  }
}

@mixin header-info-links() {
  .info-links {
    margin: 0;
    border-bottom: 1px solid rgba($white, 0.3);

    @include font(16, 20, 700);

    padding: 22px 0 11px;
    text-transform: capitalize;
    transform: translateY(-30px) translateZ(0);
    opacity: 0;
    transition: opacity 0.35s ease-out, transform 0.35s ease-out;

    .nav-active & {
      opacity: 1;
      transform: none;
      transition-delay: 600ms, 600ms;

      li {
        opacity: 1;
        transform: none;
        transition-delay: 550ms, 550ms;

        &:nth-child(2) {
          transition-delay: 600ms, 600ms;
        }
      }
    }

    li {
      margin: 0 0 19px;
      transform: translateY(-30px) translateZ(0);
      opacity: 0;
      transition: opacity 0.35s ease-out, transform 0.35s ease-out;
    }

    a {
      color: $clr25;
      font-weight: 700;
      transition: color 0.25s linear;

      &:hover {
        color: $white;
      }
    }
  }
}

@mixin header-cta-header() {
  .cta-header {
    text-align: right;

    @include font(14, 18, 700);

    margin: 0;
    padding: 3px 39px 0 0;

    @include media('>=tablet') {
      padding: 8px 40px 0 0;
    }

    @include media('<=tablet') {
      padding: 6px 39px 0 0;
    }

    @include media('>=tablet-md') {
      padding: 10px 0 0;
      transition: padding 0.25s linear;
      -webkit-transform: translateZ(0);
      transition-delay: 0.3s;
    }

    .search-active & {
      li {
        @include media('>=tablet') {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.25s linear, visiblity 0.25s linear;
          -webkit-transform: translateZ(0);
        }
      }

      .search-opener {
        .icon-search {
          @include media('<tablet') {
            opacity: 0;
            visibility: hidden;
          }
        }

        .icon-cross {
          @include media('<tablet') {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    li {
      padding: 0;

      @include vertical(middle);

      margin: 0 0 0 5.9%;
      transition: opacity 0.25s linear;
      -webkit-transform: translateZ(0);

      @include media('>=tablet-md') {
        margin: 0 0 0 4%;
        transition: opacity 0.25s linear, margin 0.25s linear;
        transition-delay: 0.3s;
      }
    }

    a {
      font-weight: 700;
      color: $gray-base;
      transition: color 0.25s linear;

      &:hover {
        @include media('>=tablet-md') {
          color: $gray-base;
          text-decoration: underline;
        }
      }
    }

    .nav-to-apply a {
      min-width: 100px;
    }

    .search-opener {
      @include media('<tablet') {
        position: relative;
        display: block;

        @include size(22px);
      }

      .txt {
        @include vertical();
      }

      .icon-search {
        @include vertical();

        line-height: 1;
        font-size: 19px;
        transition: opacity 0.25s linear;

        @include media('>=tablet') {
          padding: 0 0 0 8px;
        }
      }

      .icon-cross {
        @include btn-close(20px, 3px, $clr1, -1px, 1px);

        transition: opacity 0.25s linear;
        opacity: 0;
        visibility: hidden;
      }
    }

    .btn-primary:hover {
      text-decoration: none;
    }
  }
}
