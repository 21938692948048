/* @import '../variables/bootstrap-custom'; */
@import '../helpers/mixins';
@import '../lib/include-media';

/* aside styles */
.aside {
  text-align: center;
  margin-bottom: 12px;

  @include media('>=tablet') {
    margin-bottom: 0;
  }

  @include media('>=tablet-md') {
    margin-bottom: 40px;
  }

  address {
    font-style: normal;
    margin: 0 0 26px;

    @include media('>=tablet') {
      margin: 0 0 17px;
      padding: 0 50px;
    }

    @include media('>=tablet-md') {
      margin: 0 0 12px;
    }

    @include media('<tablet') {
      line-height: 18px;
    }

    br {
      @include media('>=tablet') {
        display: none;
      }
    }

    a {
      @include vertical();

      margin: 20px 0 0;

      @include media('>=tablet') {
        margin: 0 0 0 50px;
      }
    }
  }

  .logo {
    float: none;
    max-width: 208px;
    margin: 0 auto 47px;

    @include media('>=tablet') {
      margin-bottom: 74px;
    }

    a {
      width: auto;
      max-width: 100%;

      @include media('>=tablet') {
        width: auto;
      }

      @include media('>=tablet-md') {
        width: auto;
      }
    }
  }

  .col-xs-12 {
    @include media('<tablet') {
      width: calc(100% + 30px);
      margin: 0 -15px;
    }
  }
}

.footer-nav {
  position: relative;
  padding: 26px 30px 0 20px;

  @include media('>=tablet') {
    padding: 20px 50px;
  }

  @include media('>=widescreen') {
    padding: 22px 0;
  }

  @include pseudo-element(before, 0, 55px, auto, 5px);

  &::before {
    height: 1px;
    background: $white;

    @include media('>=tablet') {
      right: 65px;
      left: 5px;
    }

    @include media('>=widescreen') {
      right: 0;
      left: 0;
    }
  }

  ul {
    margin: 0;
    text-align: left;

    @include media('>=tablet') {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
    }

    > li {
      margin: 0 0 14px;

      @include media('>=tablet') {
        padding: 0 2% 17px;
        margin: 0;
      }

      @include media('>=widescreen') {
        padding: 0 1.26% 10px;
      }
    }
  }

  .smooth {
    display: block;

    @include hide-text();
    @include size(30px);

    border: 2px solid $white;
    border-radius: 100%;

    @include pseudo-element(before, 50%, auto, auto, 50%);

    position: absolute;
    top: -14px;
    right: 6px;

    /* opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; */

    @include media('>=tablet') {
      @include size(40px);

      right: 3px;
      top: -23px;
    }

    @include media('>=widescreen') {
      right: -87px;
    }

    &::before {
      content: "";
      background: url(../images/icons/collapse-white@2x.png) center/50% no-repeat;
      display: block;
      width: 100%;
      height: 100%;
      margin: -1px 0 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      opacity: 0.8;
    }

    .in-viewport & {
      opacity: 1;
      transform: none;
    }
  }
}
