/*
Copyright © 2013 Adobe Systems Incorporated.

Licensed under the Apache License, Version 2.0 (the “License”);
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an “AS IS” BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/*
.sitemenu {
  position: relative;
  display: block;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 3.077em;
}
*/

.sitemenu .sitemenu-top-nav-item {
  /*
  span.fa-caret-down {
    opacity: 0;
  }
  */
}

.sitemenu :focus {
  outline: 2px solid rgba(34, 95, 215, 0.3);
  -moz-outline-radius: 3px;
}

.sitemenu .sitemenu-top-nav-item a[aria-expanded]:hover,
.sitemenu .sitemenu-top-nav-item a[aria-expanded]:focus,
.sitemenu .sitemenu-top-nav-item a[aria-expanded].open {
  /*
  span.fa-caret-down {
    opacity: 1;
  }
  */
}

/*
.sitemenu .sitemenu-panel {
}
*/

.sitemenu .sitemenu-panel.open {
  visibility: visible;
  top: 3em;
  max-height: 600px;
  opacity: 1;
  z-index: 1001;
  transition: opacity 250ms ease, max-height 500ms ease, visibility 0s linear 0s, top 0s linear 0s;
}

/*
.sitemenu .sitemenu-panel a {
  display: inline-block;
  text-decoration: none;
}
*/
