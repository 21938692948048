@import '../variables/bootstrap-custom';
@import '../helpers/mixins';
@import '../lib/include-media';

.open-close,
.megamenu {
  position: absolute;
  z-index: 12;
  bottom: 0;
  left: 15px;
  right: 15px;

  @include font(16, 20, 600);

  color: $menu-text;

  @include media('>=tablet') {
    left: 40px;
    min-width: 1px;
    width: 285px;
  }

  @include media('>=tablet-md') {
    left: 30px;
  }

  @include media('<=tablet') {
    position: relative;
    left: -15px;
    right: calc(100% + 15px);
    width: calc(100% + 30px);
  }

  &.fixed-position {
    bottom: auto;
    top: 200px !important;
  }

  &.active {
    .plus {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .opener-heading {
    list-style: none;
    padding: 10px 0 10px 35px;
    background: $clr28;
    transition: all 0.3s ease-in-out;

    > li {
      margin: 0;
      $font-size-menu-opener: 17px;

      border-right: 2px solid $gray-base;

      @include media('<=tablet-md') {
        border-right: none;
      }

      display: inline-block;
      width: 100%;
      height: 30px;
    }
  }

  .mainMenu {
    list-style: none;
    max-height: 300px;

    a {
      display: block;
    }

    li {
      list-style: none;
      margin-bottom: 0;
      padding-bottom: 10px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    > li {
      padding-bottom: 20px;

      &:first-child {
        padding-top: 10px;
      }

      &:last-child {
        padding-bottom: 20px;
      }
    }

    ul a {
      //  margin-bottom: 15px;
    }

    ul ul {
      padding-left: 20px;
    }

    ul ul a {
      margin-bottom: 6px;
    }
  }

  .no-opener {
    padding-left: 30px;
  }

  .opener {
    display: inline;
    padding: 0;
    color: $menu-text;
    background: $clr28;
    transition: all 0.3s ease-in-out;
  }

  .plus {
    @include btn-plus(25px, 2px, $gray-base, 11px, auto);

    left: 15px;

    &::after,
    &::before {
      left: 18%;
      right: 18%;
    }
  }

  ul {
    margin: 0;
    padding-left: 20px;

    li {
      margin: 0 0 16px;

      &.active {
        .sub-opener {
          .plus-minus::after {
            transform: translateY(-50%) rotate(0deg);
          }
        }
      }
    }

    a {
      display: block;
      color: $menu-text;
      transition: all 0.3s ease-in-out;
    }
  }

  .sub-opener {
    position: relative;
    padding-left: 30px;

    .plus-minus {
      display: inline-block;
      width: 35px;
      height: 35px;
      position: absolute;
      top: 50%;
      margin-top: -17.5px;
      left: -10px;

      &::after,
      &::before {
        position: absolute;
        display: block;
        content: "";
        top: 50%;
        left: 30%;
        right: 30%;
        height: 2px;
        background: $gray-base;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      &::after {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  .sub-slide {
    padding: 17px 0 0 35px;
  }

  .title {
    display: block;
    margin: 0 0 15px;
    font-weight: 600;
  }

  .description {
    display: block;
    padding: 0 0 0 14px;
  }

  .section-menu {
    .opener i,
    .opener span {
      position: absolute;
      left: 5px;
      top: 17px;
    }
  }
}
