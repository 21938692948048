@import '../helpers/mixins';
@import '../lib/include-media';
@import "../variables/bootstrap-custom";

.home {
  /* OLD - See below for .home-2019 */
  .row-no-lr-pad {
    padding-left: 0;
    padding-right: 0;
  }

  .carousel-caption {
    left: 3%;
    right: 60%;
    padding: 0 15px;
    top: 3%;
    text-align: left;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.5);

    @include media('<tablet-md') {
      position: relative;
      padding-top: 20px;
      height: 120px;
      color: inherit;
      background-color: initial;
      text-shadow: none;
    }

    @include media('>=1600px') {
      left: 10%;
    }

    blockquote {
      font-weight: bold;
      font-style: italic;
      font-family: "Droid Serif", georgia, "Times New Roman", times, serif;
      font-size: 1.2em;
      margin: 0;
      line-height: 1.3;
      padding: 8px;
    }

    blockquote::before {
      content: "\201C";
    }

    blockquote::after {
      content: "\201D";
    }

    p {
      line-height: 1.1;
    }

    a {
      color: $white;
      text-decoration: underline;

      @include media('<tablet-md') {
        color: $link-color;
        text-decoration: none;
      }
    }
  }

  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img {
    object-fit: contain;

    @include media('>=widescreen') {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .carousel.slide {
    width: 100%;
    margin-bottom: 25px;
  }

  .carousel-indicators {
    bottom: -40px;
    z-index: initial;

    li {
      border: 1px solid $esc-primary;
      background-color: $white;
    }

    .active {
      background-color: $esc-primary;
    }
  }

  .carousel-control {
    opacity: 0;
    width: 5%;
  }
}

.home-2019,
.esc-carousel {
  .carousel-indicators {
    bottom: -5px;
    z-index: 5;
  }

  .carousel-indicators li {
    border: 1px solid $primary-orange;
    background-color: $white;
  }

  .carousel-indicators .active {
    background-color: $primary-orange;
  }

  .carousel-caption {
    position: static;
    left: 0;
    top: 15%;
    width: 100%;
    max-width: 100%;
    min-height: 225px;
    bottom: auto;
    background: rgba(255, 255, 255, 0.74);
    text-align: left;
    color: $black;
    text-shadow: none;
    margin-bottom: 10px;

    @include media('>=tablet') {
      position: absolute;
      max-width: 541px;
      height: 350px;

      .btn,
      h2,
      p {
        margin-left: 70px;
        margin-right: 70px;
      }
    }

    @include media('>=tablet-md') {
      height: auto;
    }

    .btn {
      margin-left: 30px;
      margin-right: 30px;
      background-color: $primary-orange;
      color: $black;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    h2,
    p {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 4px;
    }

    h2 {
      font-family: adelle, serif;
      font-weight: bold;
      font-size: 28px;

      @include media('>=tablet') {
        font-size: 33px;
      }
    }

    p {
      font-family: open-sans, sans-serif;
      font-size: 20px;
      line-height: 1.1;

      @include media('>=tablet') {
        font-size: 25px;
      }

      &.button {
        text-align: right;

        .btn {
          opacity: 100%;
          font: bold 17px/20px open-sans, sans-serif;
          text-transform: none;
          letter-spacing: 0.42px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          min-width: 220px;
        }
      }
    }
  }

  .carousel-control {
    width: 10%;
  }
}
