@import '../lib/include-media';
@import '../lib/fancybox';

/* example lightbox styles */
.popup-holder {
  position: relative;
  overflow: hidden;
  height: 0;
}

.fancybox-close {
  background: none $clr24;
  border-radius: 50%;
  display: block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;

  &::before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    bottom: auto;
    left: 20%;
    right: 20%;
    background: $white;
    height: 2px;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -1px;
  }

  &::after {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    bottom: auto;
    left: 20%;
    right: 20%;
    background: $white;
    height: 2px;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    margin-top: -1px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
