@import '../helpers/mixins';
@import '../lib/include-media';

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0.7em;
  color: inherit;
}

h1,
.h1 {
  font-weight: 300;
}

@include media('>tablet') {
  h1,
  .h1 {
    font-size: 46px;
  }

  h2,
  .h2 {
    font-size: 38px;
  }

  h3,
  .h3 {
    font-size: 32px;
  }

  h4,
  .h4 {
    font-size: 26px;
  }

  h5,
  .h5 {
    font-size: 22px;
  }

  h6,
  .h6 {
    font-size: 20px;
  }
}

@include media('>tablet-md') {
  h1,
  .h1 {
    font-size: 50px;
  }

  h2,
  .h2 {
    font-size: 42px;
  }

  h3,
  .h3 {
    font-size: 34px;
  }

  h4,
  .h4 {
    font-size: 28px;
  }

  h5,
  .h5 {
    font-size: 24px;
  }

  h6,
  .h6 {
    font-size: 22px;
  }
}

.banner h1 {
  @include media('>tablet') {
    font-size: 55px;
  }

  @include media('>tablet-md') {
    font-size: 65px;
  }
}

h3.panel-title {
  @include media('>tablet') {
    font-size: 26px;
  }

  @include media('>tablet-md') {
    font-size: 32px;
  }
}

.alt-header-banner h1,
.alt-header-banner.h1,
.alt-header-banner .h1 {
  font-size: 200%;
  margin: 0;
}

/* some bootstrap overrides that main.css did not cover, perhaps only until Dan changes via Less */
.jumbotron .h1 {
  margin-top: 0;
}
